import React, { useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

const LayoutJobDetails = () => {
  let loader = useLoaderData();
  const [job, setJob] = useState(loader?.jobDetails ?? {});
  console.log(loader, "sssssssskkkkkkkkkkk");
  const handleBack = () => {
    window.location.replace(`/layout`);
  };
  return (
    <div className="px-5 py-3 layoutcontent layoutcontent show">
      <div className="fs-5 fw-bold layoutbackButtonHover ">
        <span>
          <FontAwesomeIcon className="" height={"30px"} width={"30px"} icon={faArrowLeft}></FontAwesomeIcon>{" "}
        </span>
        <span onClick={handleBack}> Back To Search Results</span>
      </div>
      {Object.keys(job)?.length > 0 ? <div className="card mt-3 p-2">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center">
            <span className="fw-bold text-capitalize text-dark fs-3">
              {job?.displayName ? (
                <>
                  {job?.displayName == "Campaign Title" ? (
                    <b>{job?.title ?? "unkown"}</b>
                  ) : (
                    <b>{job?.job.details?.jobTitle?.text ?? "unkown"}</b>
                  )}
                </>
              ) : (
                <b>{job?.title ?? "unkown"}</b>
              )}
            </span>
            <span>
              <button
                // onClick={() => handleApplyNow(job)}
                className="btn btn-primary fw-bold newsearch-button"
              >
                <span className="newsearch-text"> Apply Now</span>
                <span className="newsearch-icon">
                  <FontAwesomeIcon
                    className=""
                    icon={faArrowRight}
                  ></FontAwesomeIcon>
                </span>{" "}
              </button>
            </span>
          </div>
          <div className="mt-3">
            <span className="fw-bold fs-6">Location : </span>
            <span className=" text-capitalize ms-1">
              {job?.job?.details?.locations[0]?.cityCanonical ? (
                <>
                  {job?.job?.details?.locations[0]?.cityCanonical},{" "}
                  {job?.job?.details?.locations[0]?.stateCanonical
                    ? job?.job?.details?.locations[0]?.stateCanonical
                    : job?.job?.details?.locations[0]?.state}
                </>
              ) : job?.job?.details?.locations[0]?.city ? (
                <>
                  {job?.job?.details?.locations[0]?.city},{" "}
                  {job?.job?.details?.locations[0]?.stateCanonical
                    ? job?.job?.details?.locations[0]?.stateCanonical
                    : job?.job?.details?.locations[0]?.state}{" "}
                </>
              ) : (
                "NA"
              )}
            </span>
          </div>
          <div className="">
            <span className="fw-bold fs-6">Pay Rate : </span>
            <span className="text-capitalize ms-2">
              {(job?.job?.details?.offeredSalary?.minvalue != 0 ||
                job?.job?.details?.offeredSalary?.minvalue == 0) &&
              job?.job?.details?.offeredSalary?.maxvalue != 0 ? (
                <span>
                  {job?.job?.details?.offeredSalary?.currency == "USD"
                    ? "$" +
                      job?.job?.details?.offeredSalary?.minvalue +
                      " - " +
                      "$" +
                      job?.job?.details?.offeredSalary?.maxvalue
                    : job?.job?.details?.offeredSalary?.currency +
                      " " +
                      job?.job?.details?.offeredSalary?.minvalue +
                      " - " +
                      job?.job?.details?.offeredSalary?.currency +
                      " " +
                      job?.job?.details?.offeredSalary?.maxvalue}
                  {" " + job?.job?.details?.offeredSalary?.period}
                </span>
              ) : (
                "NA"
              )}
            </span>
          </div>
          <div className="">
            <span className="fw-bold fs-6">Passcode : </span>
            <span>{job?.passcode ? job?.passcode : "NA"}</span>
          </div>
          <div className="my-5">
            <hr />
          </div>
          <div>
            <span>
              {job?.job?.details?.company?.description &&
              job?.job?.details?.company?.description !== ""
                ? job?.job?.details?.company?.description
                : "N/A"}
            </span>
          </div>
          <div className="my-4">
            <span>
              <button
                // onClick={() => handleApplyNow(job)}
                className="btn btn-primary fw-bold newsearch-button"
              >
                <span className="newsearch-text"> Apply Now</span>
                <span className="newsearch-icon">
                  <FontAwesomeIcon
                    className=""
                    icon={faArrowRight}
                  ></FontAwesomeIcon>
                </span>{" "}
              </button>
            </span>
          </div>
          <div className="fs-5 fw-bold text-primary layoutbackButtonHover">
            <span onClick={handleBack}> Find More Jobs </span>
            <span>
              <FontAwesomeIcon
                className=""
                icon={faArrowRight}
              ></FontAwesomeIcon>{" "}
            </span>
          </div>
        </div>
      </div> : <div className="d-flex justify-content-center">
          <img
            src="https://recruiterqa.maprecruit.com/assets/images/campaignClosed-min.png"
            width={1000}
          />
        </div>}
    </div>
  );
};

export default LayoutJobDetails;
