import React, {
  Suspense,
  useEffect,
  useRef,
  useState,
  useContext,
} from "react";
import PropTypes from "prop-types";
import { useFieldArray, Controller } from "react-hook-form";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { v4 as uuidv4 } from "uuid";
import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { SelectPicker, Toggle, AutoComplete } from "rsuite";
import { IconButton } from "rsuite";

import { StandaloneSearchBox } from "@react-google-maps/api";
import UserDateInput from "../../Date/UserDateInput";
import { DateTime } from "luxon";

// import changingDateFormat from "../../../utilities/ChangeDateFormat";

import { Trans } from "react-i18next";
import { t } from "i18next";
// import { useGetResumedbSchemaQuery } from "../../../services/resume/resumeDetailsService";
import Loading from "../../../utilities/Loading";
import usePost from "../../../customhooks/UsePost";
import ExperienceDescription from "../../account/tabs/fields/ExperienceDescription";
import { useLoaderData } from "react-router-dom";
import UserContext from "../../../app/context/UserContext";
const SkillsForm = React.lazy(() => import("./SkillsForm"));

const ExperienceModel = ({
  register,
  control,
  setValue,
  errors,
  getValues,
  skip,
  setSkip,
  watch,
  target,
  setError,
  handleSubmit,
  experienceList,
  open,
  setshowapprove,
  type,
  companyID,
  from
}) => {
  const {
    fields: experienceFields,
    append: experienceAppend,
    prepend,
    remove: experienceRemoved,
    swap,
    move,
    insert,
    replace: experienceReplace,
  } = useFieldArray({
    control,
    name: "experience",
  });
  const inputRef = useRef([]);
  const [dateClear, setDateClear] = useState(true);
  const [workSheduleDropDownValues, setWorkSheduleDropDownValues] = useState(
    ["Full-Time", "Part-Time"].map((item) => ({ label: item, value: item }))
  );
  const [suggestiondata, setsuggestionData] = useState([]);
  const [suggestedSkills, setSuggestedSkills] = useState([]);
  let loaderDetails = useLoaderData();
  const { user, setUserDetails } = useContext(UserContext);

  const {
    post: getSugesetedskills,
    data,
    isLoading: getSugesetedsloading,
    error,
  } = usePost("/user/ExpernceSuggestedSkills");

  const {
    post: getAutoSuggestions,
    data: getAutoSuggestionsData,
    isLoading: getAutoSuggestionsLoading,
    error: getAutoSuggestionsError,
  } = usePost("/user/getAutoSuggestions");

  const [showGenerateButton, setShowGenerateButton] = useState(false);
  const {
    post: showGenerateButtons,
    data: showGenerateButtondata,
    isLoading: showGenerateButtonloading,
    error: showGenerateButtonerror,
  } = usePost("/user/showGenerateButton");

  //showGenerateButton
  useEffect(() => {
    if (type == "referalresume") {
      showGenerateButtons({ companyID: companyID })
        .then((res) => {
          if (res?.status) {
            if (
              res?.result?.productSettings?.jobseekerPage
                ?.generatedResumeBuilder
            ) {
              setShowGenerateButton(
                res?.result?.productSettings?.jobseekerPage
                  ?.generatedResumeBuilder
              );
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else if((user && user?.companyID && user?.companyID !== "") || (loaderDetails && loaderDetails?.companyID !== "")){
      showGenerateButtons({companyID: user?.companyID || loaderDetails?.companyID}).then((res) => {
        if (res?.status) {
          if(res?.result?.productSettings?.jobseekerPage?.generatedResumeBuilder){
            setShowGenerateButton(res?.result?.productSettings?.jobseekerPage?.generatedResumeBuilder)
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
      }
  }, [user, type,loaderDetails]);

  const handlegetAutoSuggestions = (values) => {
    getAutoSuggestions({
      context: "job_title",
      text: values,
    }).then((res) => {
      console.log(res?.status);
      if (res?.status) {
        console.log(res?.result?.map((x) => x?.text));
        setsuggestionData(res?.result?.map((x) => x?.text));
      }
    });
  };

  // const [resumeSchema, setResumeSchema] = useState({})
  //   const {
  //       post: schema,
  //       isLoading,
  //       error: err,
  //     } = usePost("/user/getresumedbschema");

  //     useEffect(()=>{
  //       schema({get: "schema"}).then((res)=>{
  //           setResumeSchema(res?.result?.resumeSchema)
  //       }).catch((error)=>{
  //         console.log(error)
  //       })
  //     },[experienceList]);

  // useEffect(() => {
  //   if (resumeSchema) {
  //     if (resumeSchema?.schema?.values?.workPermitStatus) {
  //       setWorkSheduleDropDownValues(resumeSchema?.schema?.values?.workSchedule.map((x) => {
  //         return {
  //           label: x,
  //           value: x
  //         }

  //       }))
  //     }
  //   }
  // }, [resumeSchema])

  useEffect(() => {
    if (skip) {
      getSugesetedskills({
        title: [...new Set(getValues()?.experience?.map((x) => x?.jobTitle))],
      })
        .then((res) => {
          // console.log(res?.result?.result, "suggetstedreslu");
          setSuggestedSkills(res?.result?.result);
          setSkip(false);
          // isMounted.current = true;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [skip]);

  const Addskills = (skill, index, k) => {
    // const updatedSkills = [
    //   ...getValues().experience[index].skills,
    //   { text: skill, yearsOfExperience: "" },
    // ];
    const updatedSkills = [
      ...getValues().experience[index].skills,
      { text: skill, yearsOfExperience: 0 },
    ].filter((skillObj) => skillObj.text.trim() !== "");
    setValue(`experience[${index}].skills`, updatedSkills);

    if(getValues()?.skills?.length > 0){
      let skillArr = getValues()?.skills
      let flag = true;
      getValues()?.skills?.map((skill1) => {
        if(skill1?.skills === skill){
          flag = false;
        }
      })
      if(flag){
        skillArr.push({
          skills: skill,
          yearsOfExperience: 0,
        })
      }
      setValue("skills",skillArr)
    } else {
      let skillArr = getValues()?.skills || []
      skillArr.push({
        skills: skill,
        yearsOfExperience: 0,
      })
      setValue("skills",skillArr)
    }

    // setaddskip(true)
  };
  const handleProfileOnKeyPress = (e) => {
    // e.preventDefault()
    // setSkip(true);
    // console.log("jsjjdh");
    if (e && e.key === "Enter" && e?.target.value) {
      setSkip(true);
    }
    // isMounted.current = false;
  };
  const handleProfileOnKeyDown = (e) => {
    // console.log("jsjjdhjjjj");

    // e.preventDefault()
    // setSkip(true);
    if (e && e.key === "Enter" && e?.target?.value) {
      setSkip(true);
    }
    // isMounted.current = false;
  };

  // const {
  //   data: resumeSchema,
  //   error: resumeSchemaError,
  //   isLoading: resumeSchemaIsLoading,
  //   isFetching: resumeSchemaIsFetching,
  // } = useGetResumedbSchemaQuery({
  //   refetchOnMountOrArgChange: true,
  //   skip: false,
  // });

  // useEffect(() => {
  //   if (resumeSchema) {
  //     if (resumeSchema?.schema?.values?.workPermitStatus) {
  //       setWorkSheduleDropDownValues(resumeSchema?.schema?.values?.workSchedule.map((x) => {
  //         return {
  //           label: x,
  //           value: x
  //         }

  //       }))
  //     }
  //   }
  // }, [resumeSchema])

  useEffect(() => {
    setDateClear(false);
  }, [dateClear]);

  useEffect(() => {
    if (experienceList && experienceList.length > 0) {
      experienceReplace();
      if (target == "createProfile") {
        // console.log(experienceList, "explist")
        experienceAppend(
          experienceList.map((item, index) => {
            return {
              company: item?.company,
              jobTitle: item?.jobTitle,
              // workSchedule: item?.workSchedule,
              startDate: item?.startDate,
              endDate: item?.endDate,
              // supervisor: item?.supervisor,
              description: item?.description,
              status: item?.status,
              location: item?.location,
              skills:
                item?.skills?.length > 0
                  ? item?.skills?.map((x) => {
                      return {
                        text: x?.text,
                        yearsOfExperience: x?.yearsOfExperience,
                      };
                    })
                  : [],
            };
          })
        );
      } else if (target == "editProfile") {
        experienceAppend(
          experienceList.map((item, index) => {
            return {
              company: item?.company?.text,
              jobTitle: item?.jobTitle?.text,
              startDate:
                item?.startDate?.standard === ""
                  ? ""
                  : DateTime.fromISO(item?.startDate?.standard).toJSDate(),
              endDate:
                item?.endDate?.text === "present" ||
                item?.endDate?.text === "Present"
                  ? ""
                  : item?.endDate?.standard !== "" &&
                    DateTime.fromISO(item?.endDate?.standard).toJSDate(),
              description: item?.description,
              status: item?.status,
              location: item?.location?.text,
              skills:
                item?.skills?.length > 0
                  ? item?.skills?.map((x) => {
                      return {
                        text: x?.text,
                        yearsOfExperience: x?.yearsOfExperience,
                      };
                    })
                  : [],
            };
          })
        );
      }
    } else {
      experienceReplace();
      experienceAppend([
        {
          company: "",
          jobTitle: "",
          // workSchedule: "",
          startDate: "",
          endDate: "",
          // supervisor: "",
          description: "",
          status: false,
          location: "",
          skills: [],
        },
      ]);
    }
  }, [open]);
  const handleAddressChange = (index) => {
    let location = {
      city: "",
      country: "",
      pin: { lat: 0, lon: 0 },
      state: "",
      zipcode: "",
      text: "",
    };
    let address1 = "";

    const [place] = inputRef.current[index].getPlaces();
    console.log(place, "place233");
    if (place) {
      if (place?.address_components.length > 0) {
        for (let i in place?.address_components) {
          if (
            place?.address_components[i].types.indexOf("street_number") != -1
          ) {
            address1 += place.address_components[i].long_name + ",";
          }
          if (place.address_components[i].types.indexOf("route") != -1) {
            address1 += place.address_components[i].long_name + ",";
          }
          if (place.address_components[i].types.indexOf("neighborhood") != -1) {
            address1 += place.address_components[i].long_name + ",";
          }
          if (
            place.address_components[i].types.indexOf("sublocality_level_3") !=
            -1
          ) {
            address1 += place.address_components[i].long_name + ",";
          }
          if (
            place.address_components[i].types.indexOf("sublocality_level_2") !=
            -1
          ) {
            address1 += place.address_components[i].long_name + ",";
          }
          if (
            place.address_components[i].types.indexOf("sublocality_level_1") !=
            -1
          ) {
            address1 += place.address_components[i].long_name + ",";
          }
          if (place.address_components[i].types.indexOf("postal_code") != -1) {
            location.zipcode += place.address_components[i].long_name;
          }
          if (
            place.address_components[i].types.indexOf("postal_code_suffix") !=
            -1
          ) {
            location.zipcode += place.address_components[i].long_name;
          }
          if (place.address_components[i].types.indexOf("locality") != -1) {
            location.city += place.address_components[i].long_name;
          }
          if (
            place.address_components[i].types.indexOf(
              "administrative_area_level_1"
            ) != -1
          ) {
            location.state = place.address_components[i].long_name;
          }
          if (place.address_components[i].types.indexOf("country") != -1) {
            location.country = place.address_components[i].long_name;
          }
        }
      }
    }

    if (address1) {
      location.text += address1;
    }
    if (location.city != "") {
      location.text += location.city + ",";
    }
    if (location.state != "") {
      location.text += location.state;
    }
    if (location.zipcode != "") {
      location.text += " " + location.zipcode + ",";
    } else {
      location.text += ",";
    }
    if (location.country != "") {
      location.text += location.country;
    }
    location.pin.lat = place.geometry.location.lat();
    location.pin.lon = place.geometry.location.lng();
    setValue(`experience[${index}].location`, location.text);
    setValue(`experience[${index}].locationObJ`, location);
  };

  const handleAddExperience = () => {
    experienceAppend({
      company: "",
      jobTitle: "",
      workSchedule: "",
      startDate: "",
      endDate: "",
      supervisor: "",
      description: "",
      status: "",
      location: "",
      skills: [],
    });
  };

  return (
    <>
      {experienceFields?.length > 0 &&
        experienceFields.map((itemData, index1) => {
          // console.log(itemData, "sdggnkndgfksndgsdkgnkd");
          return (
            <div className="row py-2" /* key={itemData?.id} */ key={index1}>
              <div className="col-sm-12 col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12 justify-content-end d-flex">
                        <span
                          className="px-2 IconArea actionIcon actionIcon-sm"
                          onClick={() => {
                            experienceRemoved(index1);
                          }}
                        >
                          <IconButton
                            icon={
                              <FontAwesomeIcon
                                icon={faMinusCircle}
                              ></FontAwesomeIcon>
                            }
                            size="sm"
                          />
                        </span>
                      </div>
                    </div>
                    <div className="row ">
                      <div className={"col-sm-12"}>
                        <div className="row py-2">
                          {/* // company */}
                          <div className="col-sm-6 col-xs-12 col-md-6">
                            <div className="form-group">
                              <label>
                                {/* {t("company", { defaultValue: "Company" })} */}
                                <Trans i18nKey="company" defaults="Company" />
                              </label>
                              <Controller
                                control={control}
                                name={`experience[${index1}].company`}
                                refs={register(
                                  `experience[${index1}].company`,
                                  {
                                    required: false,
                                  }
                                )}
                                render={({
                                  field: { onChange, onBlur, value, refs },
                                }) => (
                                  <Form.Control
                                    size="sm"
                                    className="w-100"
                                    placeholder={
                                      t("enter", { defaultValue: "Enter" }) +
                                      " " +
                                      t("company", {
                                        defaultValue: "Company",
                                      })
                                    }
                                    // defaultValue={
                                    //     itemData?.campus
                                    // }
                                    refs={refs}
                                    value={value}
                                    onChange={onChange}
                                  />
                                )}
                              />
                            </div>
                          </div>
                          {/* // location */}
                          <div className="col-sm-6 col-xs-12 col-md-6">
                            <div className="form-group">
                              <label>
                                {t("location", { defaultValue: "Location" })}
                              </label>
                              <Controller
                                control={control}
                                name={`experience[${index1}].location`}
                                refs={register(
                                  `experience[${index1}].location`,
                                  {
                                    required: false,
                                  }
                                )}
                                render={({
                                  field: { onChange, onBlur, value, refs },
                                }) => (
                                  <div>
                                    <StandaloneSearchBox
                                      onLoad={(ref) => {
                                        inputRef.current[index1] = ref;
                                      }}
                                      onPlacesChanged={() =>
                                        handleAddressChange(index1)
                                      }
                                    >
                                      <Form.Control
                                        size="sm"
                                        placeholder={
                                          t("enter", {
                                            defaultValue: "Enter",
                                          }) +
                                          " " +
                                          t("location", {
                                            defaultValue: "Location",
                                          })
                                        }
                                        onChange={onChange}
                                        value={value}
                                        block="true"
                                      />
                                    </StandaloneSearchBox>
                                  </div>
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row py-2">
                          {/* // job title  */}
                          <div className="col-sm-6 col-xs-12 col-md-6">
                            <div className="form-group">
                              <label>
                                {t("jobTitle", { defaultValue: "Job Title" })}
                                <span className="text-danger">*</span>
                              </label>
                              <Controller
                                control={control}
                                name={`experience[${index1}].jobTitle`}
                                refs={register(
                                  `experience[${index1}].jobTitle`,
                                  {
                                    required: true,
                                  }
                                )}
                                render={({
                                  field: { onChange, onBlur, value, refs },
                                }) => (
                                  <AutoComplete
                                    size="sm"
                                    className="w-100"
                                    placeholder={
                                      t("enter", { defaultValue: "Enter" }) +
                                      " " +
                                      t("jobTitle", {
                                        defaultValue: "Job Title",
                                      })
                                    }
                                    // defaultValue={
                                    //     itemData?.campus
                                    // }
                                    data={suggestiondata}
                                    refs={refs}
                                    value={value}
                                    onChange={(value) => {
                                      handlegetAutoSuggestions(value);
                                      // esDataArr({ "context": "job_title", "text": value });
                                      onChange(value);
                                    }}
                                    onSelect={() => {
                                      // isMounted.current = false;
                                      setSkip(true);
                                    }}
                                    onKeyPress={handleProfileOnKeyPress}
                                    onKeyDown={handleProfileOnKeyDown}
                                  />
                                )}
                              />
                            </div>
                            {errors?.["experience"]?.[index1]?.["jobTitle"] ? (
                              <span className="text-danger">
                                {errors?.["experience"]?.[index1]?.["jobTitle"]
                                  ?.message != ""
                                  ? errors?.["experience"]?.[index1]?.[
                                      "jobTitle"
                                    ].message
                                  : "JobTitle is required"}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                          {/* // start date */}
                          {/* // end date */}
                          <div className="col-sm-6 col-xs-12 col-md-6 ">
                            <div className="form-group">
                              <label>
                                <Trans i18nKey="dates" defaults="Dates" />
                                {/* {t("dates", { defaultValue: "Dates" })} */}
                              </label>
                              <div className="d-flex align-items-center">
                                <div className="row w-100">
                                  <div className="col-md-4 col-sm-4 col-xs-12 col-lg-4">
                                    <Controller
                                      control={control}
                                      name={`experience[${index1}].startDate`}
                                      refs={register(
                                        `experience[${index1}].startDate`,
                                        {
                                          required: false,
                                        }
                                      )}
                                      render={({
                                        field: {
                                          onChange,
                                          onBlur,
                                          value,
                                          refs,
                                        },
                                      }) =>
                                        (itemData?.startDate &&
                                          itemData?.startDate != "") ||
                                        getValues()?.experience[index1]
                                          ?.startDate != "" ? (
                                          <UserDateInput
                                            defaultValue={
                                              value
                                                ? value
                                                : getValues()?.experience[
                                                    index1
                                                  ]?.startDate
                                            }
                                            placeholder={t("selectStartDate", {
                                              defaultValue: "Select Start Date",
                                            })}
                                            refs={refs}
                                            key={dateClear}
                                            setDateClear={setDateClear}
                                            // disabled={column?.editable ? false : true}
                                            onChange={(value) => {
                                              onChange(value);
                                              // setValue(`experience[${index1}].startDate`, value)
                                            }}
                                            placement="auto"
                                            oneTap
                                          />
                                        ) : (
                                          <UserDateInput
                                            placeholder={t("selectStartDate", {
                                              defaultValue: "Select Start Date",
                                            })}
                                            refs={refs}
                                            key={dateClear}
                                            setDateClear={setDateClear}
                                            // disabled={column?.editable ? false : true}
                                            onChange={(value) => {
                                              // console.log(value, "kenkngefknek");
                                              onChange(value);
                                              // setValue(`experience[${index1}].startDate`, value)
                                            }}
                                            placement="auto"
                                            oneTap
                                          />
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="col-md-4 col-sm-4 col-xs-12  col-lg-4">
                                    <Controller
                                      control={control}
                                      name={`experience[${index1}].endDate`}
                                      refs={register(
                                        `experience[${index1}].endDate`,
                                        {
                                          required: false,
                                        }
                                      )}
                                      render={({
                                        field: {
                                          onChange,
                                          onBlur,
                                          value,
                                          refs,
                                        },
                                      }) =>
                                        (itemData?.endDate &&
                                          itemData?.endDate != "") ||
                                        getValues()?.experience[index1]
                                          ?.endDate != "" ? (
                                          <UserDateInput
                                            defaultValue={
                                              value
                                                ? value
                                                : getValues()?.experience[
                                                    index1
                                                  ]?.endDate
                                            }
                                            placeholder={t("selectEndDate", {
                                              defaultValue: "Select End Date",
                                            })}
                                            refs={refs}
                                            key={dateClear}
                                            disabled={watch(
                                              `experience[${index1}].status`
                                            )}
                                            setDateClear={setDateClear}
                                            onChange={(value) => {
                                              onChange(value);
                                              setValue(
                                                `experience[${index1}].endDate`,
                                                value
                                              );
                                            }}
                                            placement="auto"
                                            oneTap
                                          />
                                        ) : (
                                          <UserDateInput
                                            // defaultValue=""
                                            refs={refs}
                                            placeholder={t("selectEndDate", {
                                              defaultValue: "Select End Date",
                                            })}
                                            key={dateClear}
                                            disabled={watch(
                                              `experience[${index1}].status`
                                            )}
                                            setDateClear={setDateClear}
                                            onChange={(value) => {
                                              onChange(value);
                                              setValue(
                                                `experience[${index1}].endDate`,
                                                value
                                              );
                                            }}
                                            placement="auto"
                                            oneTap
                                          />
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="col-md-4 col-sm-4 col-xs-12 col-lg-4">
                                    <Controller
                                      control={control}
                                      name={`experience[${index1}].status`}
                                      refs={register(
                                        `experience[${index1}].status`,
                                        {
                                          required: false,
                                        }
                                      )}
                                      render={({
                                        field: {
                                          onChange,
                                          onBlur,
                                          value,
                                          refs,
                                        },
                                      }) => (
                                        <Toggle
                                          checked={value}
                                          onChange={onChange}
                                          checkedChildren={t("working", {
                                            defaultValue: "Working",
                                          })}
                                          unCheckedChildren={t("notWorking", {
                                            defaultValue: "Not Working",
                                          })}
                                        />
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row py-2">
                          {/* // supervisor */}
                          {/* <div className="col-sm-6 col-xs-12 col-md-6">
                              <div className="form-group">
                                <label>
                                  {t("supervisor", { defaultValue: "Supervisor" })}
                                </label>
                                <Controller
                                  control={control}
                                  name={`experience[${index1}].supervisor`}
                                  refs={register(
                                    `experience[${index1}].supervisor`,
                                    {
                                      required: false,
                                    }
                                  )}
                                  render={({
                                    field: {
                                      onChange,
                                      onBlur,
                                      value,
                                      refs,
                                    },
                                  }) => (
                                    <Form.Control
                                      size="sm"
                                      className="w-100"
                                      placeholder={t("enter", { defaultValue: "Enter" }) + " " + t("Supervisor", { defaultValue: "Supervisor" })}
                                      refs={refs}
                                      value={value}
                                      onChange={onChange}
                                    />
                                  )}
                                />
                              </div>
                            </div> */}
                          {/* // work schedule */}
                          {/* <div className="col-sm-6 col-xs-12 col-md-6">
                              <div className="form-group">
                                <label>
                                  {t("workSchedule", { defaultValue: "Work Schedule" })}
                                </label>
                                <Controller
                                  control={control}
                                  name={`experience[${index1}].workSchedule`}
                                  refs={register(
                                    `experience[${index1}].workSchedule`,
                                    {
                                      required: false,
                                    }
                                  )}
                                  render={({
                                    field: {
                                      onChange,
                                      onBlur,
                                      value,
                                      refs,
                                    },
                                  }) => (
                                    <SelectPicker
                                      className="w-100"
                                      data={workSheduleDropDownValues}
                                      placeholder={t("enter", { defaultValue: "Enter" }) + " " + t("WorkSchedule", { defaultValue: "Work Schedule" })}
                                      cleanable={false}
                                      onChange={onChange}
                                      value={value}
                                      placement="auto"
                                      preventOverflow={true}
                                    />
                                  )}
                                />
                              </div>
                            </div> */}
                          </div>
                          {/* // skills form*/}
                          <Suspense
                            fallback={
                              <>
                                <Loading rows={0} />
                              </>
                            }
                          >
                            <SkillsForm
                              Addskills={Addskills}
                              suggestedSkills={suggestedSkills}
                              control={control}
                              register={register}
                              expIndex={index1}
                              skills={[]}
                              // column={column}
                              errors={errors}
                              setError={setError}
                              getValues={getValues}
                              handleSubmit={handleSubmit}
                              type="CarresSuggestedskills"
                              getSugesetedsloading={getSugesetedsloading}
                              setValue={setValue}
                              itemData={itemData}
                              from={from} 
                              />
                            {/* <SkillsForm
                              control={control}
                              register={register}
                              expIndex={index1}
                              skills={[]}
                              // column={column}
                              errors={errors}
                              setError={setError}
                              getValues={getValues}
                              handleSubmit={handleSubmit}
                            /> */}
                        </Suspense>
                        {/* 
                              // description     */}
                        <div className="row py-2">
                          <div className="col-sm-12">
                            <Suspense
                              fallback={
                                <>
                                  <Loading rows={0} />
                                </>
                              }
                            >
                              <ExperienceDescription
                                experienceList={experienceList}
                                control={control}
                                register={register}
                                expIndex={index1}
                                errors={errors}
                                // aiSuggestions={aiSuggestions}
                                setError={setError}
                                getValues={getValues}
                                setValue={setValue}
                                handleSubmit={handleSubmit}
                                itemData={itemData}
                                // handlerewrite={handlerewrite}
                                // aiRewriteSuggetions={aiRewriteSuggetions}
                                // handleGeneratedesc={handleGeneratedesc}
                                // generatedescriptionLoading={
                                //   generatedescriptionLoading
                                // }
                                // generatedescrewriteloading={
                                //   generatedescrewriteloading
                                // }
                                open={open}
                                type={"experience"}
                                setshowapprove={setshowapprove}
                                showGenerateButton={showGenerateButton}
                                // rewriteCount={rewriteCount}
                                // generateCount={generateCount}
                              />
                            </Suspense>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      <div className="my-2">
        <div className="justify-content-center d-flex">
          <span
            className="px-2 IconArea actionIcon actionIcon-lg"
            onClick={handleSubmit(handleAddExperience)}
          >
            <IconButton
              icon={<FontAwesomeIcon icon={faPlusCircle}></FontAwesomeIcon>}
              size="sm"
            />
          </span>
        </div>
        <div className="justify-content-center d-flex">
          <span className="">
            <Trans
              i18nKey="addExperienceDetails"
              defaults="Add Experience Details"
            />
          </span>
        </div>
      </div>
    </>
  );
};
ExperienceModel.propTypes = {
  register: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  getValues: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  setError: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  target: PropTypes.string,
  experienceList: PropTypes.any,
};
export default ExperienceModel;
