import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faBan,
  faCopy,
  faPencil,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import {
  toaster,
  Notification,
} from "rsuite";
import { Trans } from "react-i18next";
import Loading from "../../../../Loder";
import { v4 as uuidv4 } from "uuid"
import usePost from "../../../../customhooks/UsePost";
import UserContext from "../../../../app/context/UserContext";
import EmailEditSessionForm from "../forms/EmailEditSessionForm";
const Email = ({
  resumeId,
  setHideEmailDetailsView,
  hideEmailDetailsView,
  setHidePhoneDetailsView,
  handleClose,
  fieldObj,
  getData,
  triggerContactDetailsRef,
  setEmailClick,
  resume,setUpdateResumeDataData,
  type
}) => {
  const [email, setEmail] = useState([]);
const { user, setUserDetails } = useContext(UserContext);

  useEffect(() => {
    if (resume) {
      setEmail(resume?.resume?.profile?.emails);
    }
  }, [resume]);
  const handleEditOrCreate = () => {
    setHideEmailDetailsView(false);
    setHidePhoneDetailsView(true);
  };
  useEffect(() => { }, [hideEmailDetailsView]);

  const triggerRef = React.useRef();
  const handleCopyEmail = () => {
    toaster.push(
      <Notification
        type={"success"}
        header={<Trans i18nKey="success" defaults="Success" />}
        closable
      >
        <Trans
          i18nKey="emailCopiedSuccessfully"
          defaults="Email copied successFully"
        />
      </Notification>,
      { placement: "topEnd" }
    );
  };
  const [GeneralCommunicationOpen, setGeneralCommunicationOpen] =
    useState(false);
  useEffect(() => {
    if (GeneralCommunicationOpen) {
      setEmailClick(true)
    }
    else {
      setEmailClick(false)

    }

  }, [GeneralCommunicationOpen])
  const [handleModal, setHandleModal] = useState(false);
  return (
    <>
      {hideEmailDetailsView ? (
        <>
          <div className="row">
            <div className="col-sm-7 text-dark">
              <h6>
                <Trans i18nKey="email" defaults="Email" />
              </h6>
            </div>
            <div className="col-sm-5  d-flex justify-content-end">
              {(fieldObj?.editable || fieldObj?.editable == undefined) && type !== "referrer" ? (
                  <span
                    className="IconArea actionIcon actionIcon-sm"
                    title="Click here to add email"
                    onClick={handleEditOrCreate}
                  >
                    <FontAwesomeIcon
                      className="mx-1 cursor-pointer text-primary"
                      icon={faPlus}
                    />
                  </span>
              ) : null}
            </div>
          </div>
          <div
            className=" align-items-center"
            style={{
              minHeight: "fit-content",
              maxHeight: "200px",
              overflowY: "auto",
            }}
          >
            {email && email?.length > 0 ? (
              <>
                {email?.map((keyData, index) => {
                  return (
                    
                      <div className="row w-100 py-1 align-items-center" key={index}>
                        <div className="col-sm-12 d-flex" key={uuidv4()}>
                          {keyData?.subscribeStatus === "Opt-in" ? (
                              <span
                                className="pe-1"
                                title={keyData?.subscribeStatus}
                              >
                                <FontAwesomeIcon
                                  className="mx-1 text-success"
                                  icon={faCircleCheck}
                                />
                              </span>
                          ) : (
                              <span
                                className="pe-1"
                                title={keyData?.subscribeStatus}
                              >
                                <FontAwesomeIcon
                                  icon={faBan}
                                  className="mx-1 text-danger"
                                />
                              </span>
                          )}
                          <div key={uuidv4()} className="me-1 text-truncate text-dark">
                            {keyData?.text}
                          </div>
                          {/* <CopyToClipboard text={keyData?.text}>
                            <span
                              className="cursor-pointer"
                              title="Click here to copy email"
                              onClick={handleCopyEmail}
                            >
                              <FontAwesomeIcon
                                className="ps-1 cursor-pointer text-primary"
                                icon={faCopy}
                              />
                            </span>
                          </CopyToClipboard> */}
                        </div>
                      </div>
                    
                  );
                })}
              </>
            ) : (
                <span>
                  <span className="text-dark">
                    <Trans i18nKey="n/a" defaults="N/A" />
                  </span>
                </span>
            )}
          </div>
        </>
      ) : (
          <React.Suspense fallback={<Loading rows={0} />}>
            <EmailEditSessionForm
              resumeId={resumeId}
              companyID={resume?.companyID}
              clientID={resume?.clientID}
              triggerRef={triggerRef}
              email={email}
              setHideEmailDetailsView={setHideEmailDetailsView}
              setHidePhoneDetailsView={setHidePhoneDetailsView}
              getData={getData}
              triggerContactDetailsRef={triggerContactDetailsRef}
                setUpdateResumeDataData={setUpdateResumeDataData}
            />
          </React.Suspense>
      )}
    </>
  );
};

export default Email;
