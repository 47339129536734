import React, { useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";

function JobsCard({ job, onClick, isSelected }) {
  const [Loactionreslut, setLocationreslut] = useState("NA");

  // console.log(job, "JObBfdhdjfo");

  useEffect(() => {
    let objectWithNonEmptyCity = null;
    for (let i = 0; i < job?._source?.job?.details?.locations.length; i++) {
      const location = job?._source?.job?.details?.locations[i];
      if (location.city && location.city.trim() !== "") {
        objectWithNonEmptyCity = location;
        break;
      }
    }
    if (objectWithNonEmptyCity !== null) {
      setLocationreslut(objectWithNonEmptyCity);
    } else {
      setLocationreslut("NA");
    }
  }, [Loactionreslut, job]);

  return (
    <div
      className={`card border-0   cardRadius w-100 mt-3 ${isSelected ? "cardActive" : "selected"}`}
      onClick={() => onClick(job)}
      style={{ cursor: "pointer" }}
    >
      <div className="card-body  p-4">
        <div className="row">
        <h4 className="">
              <b>{job?._source?.job?.details?.jobTitle?.text ?? "NA"}</b>
            </h4>
          <div className="col-6">
           
            <span className="d-flex align-items-center text-center">
              <h6>
                <Trans i18nKey="jobID" defaults="Job ID" /> {":"}
              </h6>
              <h6 className="text-secondary d-flex ms-2">
                {job?._source?.passcode}
              </h6>
            </span>
            <span className="d-flex align-items-center text-center">
              <h6>
                {" "}
                <Trans i18nKey="jobType" defaults="Job type" /> {":"}
              </h6>
              <h6 className="ms-2">
                {job?._source?.job?.details?.jobTitle?.jobType ?? "NA"}
              </h6>
            </span>
            <span className="d-flex align-items-center text-center">
              <h6>
                <i className="fa-solid fa-location-dot"></i>
              </h6>
              <h6 className="ms-2">
                {Loactionreslut?.cityCanonical ? (
                  <>
                    {Loactionreslut?.cityCanonical},{" "}
                    {Loactionreslut?.stateCanonical
                      ? Loactionreslut?.stateCanonical
                      : Loactionreslut?.state}
                  </>
                ) : Loactionreslut?.city ? (
                  <>
                    {Loactionreslut?.city},{" "}
                    {Loactionreslut?.stateCanonical
                      ? Loactionreslut?.stateCanonical
                      : Loactionreslut?.state}{" "}
                  </>
                ) : (
                  "NA"
                )}
              </h6>
            </span>
          </div>
          <div className="col-6">
            <span className=" d-flex align-items-center text-center">
              <h6 className="me-2">
                <i className="fa-solid fa-sack-dollar"></i>{" "}
              </h6>
              <h6 className="ms-1 text-secondary">
                <span className="d-flex align-items-center text-center">
                  <span className=" ">
                    {job?._source?.job?.details?.offeredSalary?.minvalue ==
                    job?._source?.job?.details?.offeredSalary?.maxvalue ? (
                      <>
                        {job?._source?.job?.details?.offeredSalary?.minvalue ??
                          "0"}
                      </>
                    ) : (
                      <>
                        {job?._source?.job?.details?.offeredSalary?.minvalue
                          ? job?._source?.job?.details?.offeredSalary?.minvalue
                          : "0"}
                        -
                        {job?._source?.job?.details?.offeredSalary?.maxvalue
                          ? job?._source?.job?.details?.offeredSalary?.maxvalue
                          : "0"}
                      </>
                    )}
                  </span>
                  <span>
                    {job?._source?.job?.details?.offeredSalary?.period
                      ? job?._source?.job?.details?.offeredSalary?.period
                      : "NA"}
                  </span>
                </span>
              </h6>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JobsCard;
