import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { NavLink } from "react-router-dom";
import ReferFriend from "../jobs/ReferFriend";
import ApplyNow from "../jobs/ApplyNow";



function DisplayJob({ job }) {


  //  console.log(job,"hlbovuftsluyaukl")
     const [showLink,setShowLink]=useState(false)
     const [updatadataMri,setupdatadataMri] =useState(false)
     const [Loactionreslut, setLocationreslut] = useState("NA");

    // useEffect(() => {
    //     console.log(job);
    //     if (job) {
      
    //     }
    
      //   if (loaderDetails?.jobDetails) {
      //       job = loaderDetails?.jobDetails ;
      //   //   setJobDetails(loaderDetails?.jobDetails);
      //   //   setcampaignID(loaderDetails?.jobDetails?._id);
      //   //   setPasscode(loaderDetails?.jobDetails?.passcode);
      //   //   // if(loaderDetails?.jobDetails?.displayName && loaderDetails?.jobDetails?.displayName == "Campaign Title"){
      //   //   setJobTitle(
      //   //     loaderDetails?.jobDetails?.urlTitle
      //   //       ?.split(" ")
      //   //       .join("-")
      //   //       .replace(/-+/g, "-")
      //   //   );
      //     // }else{
      //     //   setJobTitle(loaderDetails?.jobDetails?.job?.details?.jobTitle?.text.split(' ').join('-') )
      //     // }
      //     console.log(loaderDetails,'job')
      //   }
      // }, [job, loaderDetails]);
      useEffect(() => {
        if (typeof window !== "undefined") {
          if (window.location.pathname == "/list/:searchID") {
            setShowLink(true);
          }
        }
      }, [job]);
      useEffect(() => {
        let objectWithNonEmptyCity = null;
        let jobdetils =job?._source;
        for (let i = 0; i < jobdetils?.job?.details?.locations.length; i++) {
          const location = jobdetils?.job?.details?.locations[i];
          if (location.city && location.city.trim() !== "") {
            objectWithNonEmptyCity = location;
            break;
          }
        }
        if (objectWithNonEmptyCity !== null) {
          setLocationreslut(objectWithNonEmptyCity);
        } else {
          setLocationreslut("NA");
        }
      }, [ job]);


  return (
    <>
      <div className="card  jobCardRadius mt-3 h-auto">
        <div className="card-body p-4">
        <h3>  <big>
            {" "}
            {/* <h1>{job?._source?.job?.details?.jobTitle?.text ?? "NA"} </h1> */}
            {job?._source?.displayName ? (
                <>
                  {job?._source?.displayName == "Campaign Title" ? (
                    <b>{job?._source?.title ?? "unkown"}</b>
                  ) : (
                    <>
                      {job?._source?.job.details?.jobTitle?.text ?? "unkown"}
                    </>
                  )}
                </>
              ) : (
                <b>{job?._source?.title ?? "unkown"}</b>
              )}
          </big>
          </h3>

         

          <div className="row mt-5">
            <div className="col-6">
              <span className="d-flex align-items-center">
                <h6 className="me-2">
                  {" "}
                  <Trans i18nKey="jobId" defaults="Job ID" />
                  {" :"}
                </h6>
                <h6 className="text-secondary">
                  {job?._source?.passcode ?? "NA"}
                </h6>
              </span>
              <span className="d-flex align-items-center mb-2">
                <h6 className="me-2">
                  {" "}
                  <Trans i18nKey="jobType" defaults="Job Type" />
                  {" :"}
                </h6>
                <h6 className="text-secondary">
                  {job?._source?.job?.details?.jobTitle?.jobType ?? "NA"}
                </h6>
              </span>
              <span className="">
             < ApplyNow campaignID={job?._id} setupdatadataMri={setupdatadataMri}/>
              </span>
            </div>
            <div className="col-6">
            <span className="d-flex align-i tems-center ">
            <h6 className="me-2">
              <Trans i18nKey="salary" defaults="Salary" />

            </h6>
            <h6 className="text-secondary ">
              {job?._source?.job?.details?.offeredSalary?.minvalue ?? "0"} {" - "}
              {job?._source?.job?.details?.offeredSalary?.maxvalue ?? "0"}{" "}
              {job?._source?.job?.details?.offeredSalary?.period ?? "NA"}
            </h6>
          </span>
              <span className="d-flex align-items-center">
                <b>
                  {" "}
                  <h6 className="me-2 mb-2">
                    <Trans i18nKey="location" defaults="Location" />
                    {" :"}
                  </h6>
                </b>
                <h6 className="text-secondary">
                  {/* {job?._source?.job?.details?.locations?.[0]?.city ?? "NA"},
                  {job?._source?.job?.details?.locations?.[0]?.state ?? "NA"} */}
                            {Loactionreslut?.cityCanonical ? (
                              <>
                                {Loactionreslut?.cityCanonical},{" "}
                                {Loactionreslut?.stateCanonical
                                  ? Loactionreslut?.stateCanonical
                                  : Loactionreslut?.state}
                              </>
                            ) : Loactionreslut?.city ? (
                              <>
                                {Loactionreslut?.city},{" "}
                                {Loactionreslut?.stateCanonical
                                  ? Loactionreslut?.stateCanonical
                                  : Loactionreslut?.state}{" "}
                              </>
                            ) : (
                              "NA"
                            )}
                </h6>
              </span>
              <div className="d-flex align-items-end">
               <ReferFriend campaignID={job?._id} />
              </div>
            </div>
          </div>

          <div>
            <div className="mt-3">
              <h3>
                <Trans i18nKey="jobDetails" defaults="Job Details" />
              </h3>
             <hr />   
               <div className="Jobdetailstext"> {" "}
                {job?._source?.job?.details?.jobDescription?.text &&
                  parse(job?._source?.job?.details?.jobDescription?.text)}
              </div>
            </div>
          </div>
          {!showLink &&  (
           <div className="d-flex justify-content-end mt-3">
            {/* <NavLink
              target="_blank"
              className="ps-2"
              to={`/job/${job?._source?.passcode}`}
            >
              <Trans i18nKey="seeFullJobDetails" defaults="See full job details" />
            </NavLink> */}
             <NavLink
               target="_blank"
               className="ps-2"
               to={
                 typeof window !== "undefined" && window.location.pathname.includes("careers")
                   ? `/careers/job/${job?._source?.passcode}`
                   : `/job/${job?._source?.passcode}`
               }
                 >
               <Trans i18nKey="seeFullJobDetails" defaults="See full job details" />
             </NavLink>
          </div>
          )
          }
        
        </div>
      </div>
    </>
  );
}

export default DisplayJob;
