import React, { useState, useEffect, useCallback } from "react";
import { useLoaderData, useSearchParams } from "react-router-dom";
import useIsSsr from "../../customhooks/UseIsSSR";
import AvailableSlots from "./AvailableSlots";
import ScheduledSlotDetails from "./ScheduledSlotDetails";
import axios from "axios";
import ClosedCampaign from "./ClosedCampaign";
import { useToaster, Notification } from "rsuite";
import { Trans, useTranslation } from "react-i18next";

export default function ScheduleVideo() {
    let data = useLoaderData();
    const [searchParams] = useSearchParams();
    const [interviewID, setInterviewID] = useState(null);
    const [campaignID, setCampaignID] = useState(null);
    const [companyID, setCompanyID] = useState(null);
    const [roundNumber, setRoundNumber] = useState(null);
    const [isSlotSelected, setIsSlotSelected] = useState(false);
    const [slotDetails, setSlotDetails] = useState(null);
    const [payload, setPayload] = useState(null);
    const [profileCreate, setProfileCreate] = useState(false);
    const [active, setActive] = useState(true)
    const toaster = useToaster();
    const { t } = useTranslation()
    const [pageStatus, setPageStatus] = useState(true)

    // console.log("data from app", data);

    useEffect(() => {
        if (data) {
            if (data?.message?.includes("Campaign is not active")) {
                // throw new Error("Bad Request", { status: 400 });
                setActive(false)
            } else {
                if (data?.campaignID) {
                    setCampaignID(data?.campaignID)
                    setCompanyID(data?.companyID)
                } else {
                    setSlotDetails(data)
                }
            }
        }
    }, [data]);

    useEffect(() => {
        if (searchParams.get("iid") !== null) {
            setInterviewID(searchParams.get("iid"));
        }
        if (searchParams.get("round") !== null) {
            setRoundNumber(searchParams.get("round"))
        }
        if (searchParams.get("companyid") !== null) {
            setCompanyID(searchParams.get("companyid"))
        }
    }, [searchParams])

    const getcookieDetails = async () => {
        try {
            let cookieDetails = await axios.get(window.location.origin + "/jsv4/webapi/getcookie");
            // console.log("ccccccccccccc", cookieDetails?.data);

            cookieDetails = cookieDetails?.data;
            cookieDetails = cookieDetails[`autoScheduleSlotDetails_${searchParams.get("cid")}_${searchParams.get("round")}`];

            if (cookieDetails) {
                cookieDetails = JSON.parse(cookieDetails)
                if (cookieDetails?.interviewID) {
                    setInterviewID(cookieDetails?.interviewID);
                }
                if (cookieDetails?.companyID) {
                    setCompanyID(cookieDetails?.companyID);
                }
            }
        } catch (error) {
            console.log("Error in getcookie", error)
        }
    }

    useEffect(() => {
        if (searchParams.get("cid") !== null) {
            getcookieDetails();
        }
    }, [searchParams])

    useEffect(() => {
        if (interviewID && roundNumber && campaignID && companyID) {
            setProfileCreate(false)
            setPayload({
                interviewID: interviewID,
                roundNumber: roundNumber,
                campaignID: campaignID,
                companyID: companyID
            })
        } else {
            if (campaignID && roundNumber) {
                setProfileCreate(true)
                setSlotDetails({
                    campaignID: campaignID,
                    roundNumber: roundNumber
                })
            } else {
                if (data?.resumeID && data?.userID && companyID) {
                    setPayload({
                        from: "profile",
                        resumeID: data?.resumeID ?? "",
                        companyID: companyID,
                        userID: data?.userID ?? ""
                    })
                }
            }
        }
    }, [interviewID, roundNumber, campaignID, companyID, data]);

    const checkingInterviewStatus = async () => {
        try {
            let isInterviewBooked = await axios.get(
                window.location.origin +
                "/jsv4/webapi/schedulevideo/checkInterviewBookingStatus",
                {
                    params: payload,
                }
            );
            // console.log("isInterviewBooked", isInterviewBooked?.data)

            if (isInterviewBooked?.data?.status) {
                if (isInterviewBooked?.data?.result?.status) {
                    if (isInterviewBooked?.data?.result?.selected) {
                        setIsSlotSelected(true);
                        setSlotDetails(isInterviewBooked?.data?.result);
                    } else {
                        if (searchParams.get("cid") !== null) {
                            setProfileCreate(true)
                            setSlotDetails({
                                campaignID: campaignID,
                                roundNumber: roundNumber
                            })
                        } else {
                            setProfileCreate(false)
                            if (searchParams.get("rid") !== null) {
                                setSlotDetails({
                                    resumeID: data?.resumeID ?? "",
                                    companyID: companyID,
                                    userID: data?.userID ?? ""
                                })
                            } else {
                                setSlotDetails({
                                    interviewID: interviewID,
                                    companyID: companyID,
                                    roundNumber: roundNumber
                                })
                            }
                        }
                    }
                } else {
                    if (isInterviewBooked?.data?.result?.message?.includes("interviewDetailsNotFound")) {
                        setPageStatus(false)
                    } else {
                        toaster.push(
                            <Notification type={"error"} header={t("error", { defaultValue: "error" })} closable>
                                {t("somethingWentWrongPleaseTryAgainLater", { defaultValue: "Something went wrong please try again later" })}
                            </Notification>,
                            { placement: "topEnd" }
                        );
                    }
                }
            } else {
                toaster.push(
                    <Notification type={"error"} header={t("error", { defaultValue: "error" })} closable>
                        {t("somethingWentWrongPleaseTryAgainLater", { defaultValue: "Something went wrong please try again later" })}
                    </Notification>,
                    { placement: "topEnd" }
                );
            }
        } catch (error) {
            // console.log("ERROR IN checkInterviewBookingStatus", error.stack);
            return { error: error.stack };
        }
    }

    useEffect(() => {
        if (payload !== null) {
            checkingInterviewStatus();
        }
    }, [payload])

    const bookedSlotDetails = useCallback((data) => {
        // console.log("dhgshdhsdfsdsdf", data)
        // setData(data)
        setSlotDetails(data)
    }, [])

    return (
        <>
            {
                active ?
                    (<>
                        {
                            pageStatus ?
                                (<>
                                    {
                                        isSlotSelected ?
                                            (<ScheduledSlotDetails data={slotDetails} />)
                                            :
                                            (
                                                <AvailableSlots
                                                    data={slotDetails}
                                                    isSlotSelected={isSlotSelected}
                                                    setIsSlotSelected={setIsSlotSelected}
                                                    bookedSlotDetails={bookedSlotDetails}
                                                    profileCreate={profileCreate}
                                                />
                                            )
                                    }
                                </>)
                                :
                                < div className="text-center fs-4 pt-4">
                                    <Trans i18nKey="thisInvitationHasExpired" defaults="This Invitation has Expired" />
                                </div>
                        }
                    </>)
                    : (<ClosedCampaign />)
            }
        </>
    )
}
