// import "../node_modules/rsuite/dist/rsuite.min.css";
// import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
// import type { RouteObject } from "react-router-dom";
import {
  Outlet,
  Link,
  useLoaderData,
  useParams,
  redirect,
  useNavigate,
} from "react-router-dom";
import queryString from "query-string";
import useIsSsr from "./customhooks/UseIsSSR";
// import NavHeader from "./NavHeader/Header/Header";
// import NavFooter from "./NavHeader/Footer/Footer";

// import axios from "axios";
// import { ObjectId } from "mongodb";
import JobDetails from "./components/jobs/JobDetails";
import MyAccount from "./components/account/MyAccount";
import BasicDetails from "./components/account/BasicDetails";
import FavouriteJobs from "./components/account/FavouriteJobs";
import Resume from "./components/account/tabs/Resume";
import ProfileBasicDetails from "./components/account/tabs/ProfileBasicDetails";
import ProfileRecommendedJobs from "./components/account/tabs/ProfileRecommendedJobs";
import Search from "./components/Search";
// import axios from "axios";
import axios from "./customhooks/axiosConfig";
// import searchJson from "../utilities/search.js"
// define base url

import React, { useContext, useRef, useEffect, useState } from "react";
import Jobs from "./components/jobs/Jobs";
import { useTranslation } from "react-i18next";
import ScheduleVideo from "./components/scheduleVideo/ScheduleVideo";
import { SocketContext } from "./app/context/SocketContext";
import Login from "./components/Login/Login";
import ConfirmSchedule from "./components/scheduleVideo/ConfirmSchedule";
import UserContext from "./app/context/UserContext";
import UnsubscribeEmail from "./components/Unsubscribe/UnsubscribeEmail";
import ErrorBoundary from "./ErrorBoundary";
import LayoutMain from "./components/MedicalLayout/LayoutMain";
import ListJobs from "./components/MedicalLayout/ListJobs";
import Referral from "./components/referralpage/Referral";
import NewLayout from "./components/newLayout2/NewLayout";
import LayoutJobDetails from "./components/newLayout2/LayoutJobDetails";
import CareersJobdeatails from "./components/jobs/careersJobdeatails/CareersJobdeatails";
import CreateProfile from "./components/jobs/CreateProfile";
// const baseURL = "http://localhost:3105";
// const baseURL = "/";
// axios.defaults.baseURL = baseURL;

// add header to axios
axios.defaults.withCredentials = true;
// axios.interceptors.request.use((config) => {
//   config.headers["Auth-key"] = "37tAi1XgkzWTgl3eZ8fQJNcBIEf";
//   return config;
// });

// const getHtmlQueryType = (type) => {
//   switch (type) {
//     case "tag":
//       return "";
//     case "id":
//       return "#";
//     case "class":
//       return ".";
//     default:
//       return "";
//   }
// };

const getOrigin = (url) => {
  let t = new URL(url);
  return t?.origin;
};
export const routes = [
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorBoundary />,

    loader: async ({ request, params, context }) => {
      console.log("STEP20:", context?.domain);
      return context ?? {};
      // try {
      //   // console.log("sdgnjksgnjk", getOrigin(request.url) );
      //   // console.log(getOrigin(request.url) +"/api/getTheme")
      //   let data = await axios.get(getOrigin(request.url) + "/jsv4/webapi/getTheme");
      //   // console.log(data?.data?.data);
      //   return {
      //     themes: data?.data ?? {},
      //   };
      // } catch (error) {
      //   console.log(error.stack);
      //   return {};
      // }
    },

    children: [
      {
        index: true,
        // loader: async ({ request, params }) => {
        //   console.log("sdgnjksgnjk", params);
        //   try {
        //     let data = await axios.get("/api/getTheme");
        //     console.log(data);
        //     return data?.data?.data ?? {};
        //   } catch (error) {
        //     console.log(error.stack);
        //     return {};
        //   }
        // },
        element: <Home />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/layout",
        id: "newLayout",
        loader: async ({ request, params, context }) => {
          try {
            let { keywords, location, radius, experience, date } = params;
            let search = request?.url?.split("?")?.[1];
            search = queryString.parse(search);
            let searchbody;
            if (search?.searchID !== "" && search?.searchID !== undefined) {
              try {
                let searchreslutsdata = await axios.get(
                  context.domain +
                    `/jsv4/webapi/jobsearch/SearchIdResluts/${search.searchID}`
                );
                if (searchreslutsdata?.data?.data) {
                  searchbody = searchreslutsdata;
                } else {
                  searchbody = {};
                }
              } catch (error) {
                console.log("STEP24:newLayouterrorfromSearchIdResluts", error.stack);
                searchbody = {};
              }
            } else {
              searchbody = {};
            }
            console.log(searchbody,"searchhhhhhhhhhhhhhhhhhhhhhhh")
            let searchdata;
            if (
              searchbody?.data?.data?.body &&
              searchbody?.data?.data?.body.companyID == context?.companyID
            ) {
              searchdata = searchbody?.data?.data?.body;
              searchdata.searchID = search?.searchID;
              searchdata.newSearch = false;
              searchdata.searchqureydata = true;
              searchdata.filter = [];
            } else {
              searchdata = {};
              searchdata.companyID = context?.companyID ?? "";
              searchdata.searchAttributes = false;
              searchdata.newSearch = true;
              searchdata.sort = [];
              searchdata.search = [];
              searchdata.projection = [
                "title",
                "createdAt",
                "displayName",
                "job.details.jobTitle.text",
                "job.requirements.skills.skills.text",
                "job.details.locations",
                "job.details.jobTitle.jobType",
                "job.details.offeredSalary",
                "job.details.jobTiming",
                "job.details.workingHours",
                "job.metaData.postedDate",
                "job.details.jobDescription.text",
                "job.otherInformation.industry.classified.industryLabel",
                "customData.6112806bc9147f673d28c6eb.64abb309db7899f5908c77aa.value",
                "passcode",
              ];
              if (search?.keywords) {
                searchdata.searchKey = search.keywords.split(", ");
              }
              if (search?.industry) {
                searchdata.search.push(
                  {
                    type: "industry",
                    industry: search.industry.split(", "),
                  },
                );
              }
              let location = {
                // city: search.city /* || search.contry, */,
                // state: search.state,
                // country: search.country /* || search.contry */,
                // zipcode: search.zipcode,
              };
              if (search?.locationtext) {
                location.text = search.locationtext;
              }
              if (search?.city) {
                location.city = search.city;
              }
              if (search?.state) {
                location.state = search.state;
              }

              if (search?.country) {
                location.country = search.country;
              }
              if (search?.zipcode) {
                location.zipcode = search.zipcode;
              }
              if (
                search?.lat &&
                search?.lon &&
                search?.lat != "" &&
                search?.lon != ""
              ) {
                location.pin = {
                  lat: search.lat,
                  lon: search.lon,
                };
              }

              if (Object.values(location).some((value) => value)) {
                searchdata.search.push({
                  type: "location",
                  location: [location],
                });
              }

              if (search?.experience) {
                searchdata.search.push({
                  type: "experience",
                  maxExp: search.experience,
                  minExp: 0,
                });
              }
            }
            if (search?.minExp || search?.maxExp) {
              searchdata.search.push({
                type: "experience",
                maxExp: search.maxExp,
                minExp: search.minExp,
              });
            }
            searchdata.skip = 0;
            searchdata.page = 1;
            searchdata.size = 10;

            if (searchdata?.search?.length > 0 || searchdata?.searchKey?.length > 0) {
              searchdata.sort = [{ type: "relevance", order: "desc" }];
            } else {
              searchdata.sort = [{ type: "date", order: "desc" }];
            }

            let data;
            try {
              if (searchdata?.companyID !== "") {
                console.log("STEP26:domain", context.domain);
                data = await axios.post(
                  context.domain + "/jsv4/webapi/jobsearch/search",
                  searchdata
                );
                // console.log("STEP38:", data?.data?.searchID);
              } else {
                data = {};
              }
            } catch (error) {
              console.log("STEP39:newLayoutjobsearchError", error);
              data = {};
            }
            // console.log("STEP40:serachjobsdata", data?.data?.searchID);
            return {
              companyID: context?.companyID ?? "",
              jobs: data?.data ?? {},
              searchbody: searchbody?.data?.data?.body ?? searchdata,
              companyName: context?.themeVariables?.companyName ?? "",
            };
          } catch (error) {
            console.log("STEP41:newLayouterrorfrom serchJobs", error.stack);
            return {};
          }
        },
        element: <NewLayout />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/jobresult/:passcode",
        loader: async ({ request, params, context }) => {
          let { passcode } = params;
          console.log("params", params);
          try {
            let data = await axios.get(
              context.domain + "/jsv4/webapi/getjobdetailsbypass/" + passcode
            );
            console.log(data?.data, "jobdearrahh");
            return {
              jobDetails: data?.data?.data ?? {},
              companyID: context?.companyID ?? "",
              companyName: context?.themeVariables?.companyName ?? "",
            };
          } catch (error) {
            console.log(error.stack);
            return {};
          }
        },
        element: <LayoutJobDetails />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/myaccount",
        loader: async ({ request, params, context }) => {
          return context ?? {};
        },
        element: <MyAccount />,
        errorElement: <ErrorBoundary />,
        children: [
          {
            path: "/myaccount/basicdetails",
            element: <BasicDetails />,
            errorElement: <ErrorBoundary />,
            children: [
              {
                path: "/myaccount/basicdetails/resume",
                element: <Resume />,
                errorElement: <ErrorBoundary />,
              },
              {
                path: "/myaccount/basicdetails/profilebasicdetails",
                element: <ProfileBasicDetails />,
                errorElement: <ErrorBoundary />,
              },
              {
                path: "/myaccount/basicdetails/profilerecommendedjobs",
                element: <ProfileRecommendedJobs />,
                errorElement: <ErrorBoundary />,
              },
            ],
          },
          {
            path: "/myaccount/guest",

            element: <BasicDetails />,
            errorElement: <ErrorBoundary />,
          },

          {
            path: "/myaccount/favouritejobs",
            element: <FavouriteJobs />,
            errorElement: <ErrorBoundary />,
          },
        ],
      },
      
      {
        path: "/careers/myaccount",
        loader: async ({ request, params, context }) => {
          return context ?? {};
        },
        element: <MyAccount />,
        errorElement: <ErrorBoundary />,
        children: [
          {
            path: "/careers/myaccount/basicdetails",
            element: <BasicDetails />,
            errorElement: <ErrorBoundary />,
            children: [
              {
                path: "/careers/myaccount/basicdetails/resume",
                element: <Resume />,
                errorElement: <ErrorBoundary />,
              },
              {
                path: "/careers/myaccount/basicdetails/profilebasicdetails",
                element: <ProfileBasicDetails />,
                errorElement: <ErrorBoundary />,
              },
              {
                path: "/careers/myaccount/basicdetails/profilerecommendedjobs",
                element: <ProfileRecommendedJobs />,
                errorElement: <ErrorBoundary />,
              },
            ],
          },
          {
            path: "/careers/myaccount/guest",

            element: <BasicDetails />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: "/careers/myaccount/favouritejobs",
            element: <FavouriteJobs />,
            errorElement: <ErrorBoundary />,
          },
        ],
      },

      {
        path: "/jobdetails/:campaignId",
        loader: async ({ request, params, context }) => {
          let { campaignId } = params;
          console.log("params", params);
          try {
            let data = await axios.get(
              context.domain + "/jsv4/webapi/getjobdetails/" + campaignId
            );
            console.log(data?.data, "jobdearrahh");
            return {
              jobDetails: data?.data?.data ?? {},
              companyID: context?.companyID ?? "",
              companyName: context?.themeVariables?.companyName ?? "",
            };
          } catch (error) {
            console.log(error.stack);
            return {};
          }
        },
        element: <JobDetails />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/job/:passcode",
        loader: async ({ request, params, context }) => {
          let { passcode } = params;
          console.log("params", params);
          try {
            let data = await axios.get(
              context.domain + "/jsv4/webapi/getjobdetailsbypass/" + passcode
            );
            console.log(data?.data, "jobdearrahh");
            return {
              jobDetails: data?.data?.data ?? {},
              companyID: context?.companyID ?? "",
              companyName: context?.themeVariables?.companyName ?? "",
            };
          } catch (error) {
            console.log(error.stack);
            return {};
          }
        },
        element: <JobDetails />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/careers/job/:passcode",
        loader: async ({ request, params, context }) => {
          let { passcode } = params;
          console.log("params", params);
          try {
            let data = await axios.get(
              context.domain + "/jsv4/webapi/getjobdetailsbypass/" + passcode
            );
            console.log(data?.data, "jobdearrahh");
            return {
              jobDetails: data?.data?.data ?? {},
              companyID: context?.companyID ?? "",
              companyName: context?.themeVariables?.companyName ?? "",
            };
          } catch (error) {
            console.log(error.stack);
            return {};
          }
        },
        element: <JobDetails />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/job/:passcode/:jobTitle",
        loader: async ({ request, params, context }) => {
          let { passcode } = params;
          console.log("params", params);
          try {
            let data = await axios.get(
              context.domain + "/jsv4/webapi/getjobdetailsbypass/" + passcode
            );
            console.log(data?.data, "jobdearrahh");
            return {
              jobDetails: data?.data?.data ?? {},
              companyID: context?.companyID ?? "",
              companyName: context?.themeVariables?.companyName ?? "",
            };
          } catch (error) {
            console.log(error.stack);
            return {};
          }
        },
        element: <JobDetails />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/careers/job/:passcode/:jobTitle",
        loader: async ({ request, params, context }) => {
          let { passcode } = params;
          console.log("params", params);
          try {
            let data = await axios.get(
              context.domain + "/jsv4/webapi/getjobdetailsbypass/" + passcode
            );
            console.log(data?.data, "jobdearrahh");
            return {
              jobDetails: data?.data?.data ?? {},
              companyID: context?.companyID ?? "",
              companyName: context?.themeVariables?.companyName ?? "",
            };
          } catch (error) {
            console.log(error.stack);
            return {};
          }
        },
        element: <JobDetails />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/jobs",
        element: <Jobs />,
        id: "jobs",
        errorElement: <ErrorBoundary />,
        loader: async ({ request, params, context }) => {
          console.log("STEP21:", context?.domain);
          // console.log("lkdfklsdfkllskln " + request.session);
          // let { keywords, location, radius, experience, date } = params;
          // get search string from url
          // console.log(context?.companyID, "sedrtyju");
          // let search = request?.url?.split("?")?.[1];
          // console.log("search", queryString.parse(search));
          // search = queryString.parse(search);
          // console.log(search.keywords, "params");
          // console.log(params);
          // call job list api from here
          // import search json from utilities
          // let searchJson = await import("./utilities/search.json");
          // console.log("searchJson ", searchJson)
          // searchJson = JSON.parse(JSON.stringify(searchJson));

          // console.log(searchbody?.data?.data?.body);
          // console.log(searchJson);
          // let companyID;
          // if (
          //   (search.keywords !== "" && search.keywords !== undefined) ||
          //   (search.experience !== "" && search.experience !== undefined) ||
          //   (search.location !== "" && search.location !== undefined)
          // ) {
          //   companyID = context?.companyID?.toString();

          // }
          // console.log(search.page, "uhty");
          // let body = {
          //   searchAttributes: false,
          //   size: 10,
          //   skip: 0,
          //   searchKey: [],
          //   filter: [

          //   ],
          //   projection: [
          //     "title",
          //     "job.details.jobTitle.text",
          //     "job.requirements.skills.skills.text",
          //     "job.details.locations",
          //     "job.details.jobTitle.jobType",
          //     "job.details.offeredSalary",
          //     "job.details.jobTiming",
          //     "job.details.workingHours",
          //     "job.metaData.postedDate",
          //     "job.details.jobDescription.text",
          //   ],

          //   companyID: companyID,
          // };
          try {
            let { keywords, location, radius, experience, date } = params;
            let search = request?.url?.split("?")?.[1];
            search = queryString.parse(search);

            /*
            
           fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${apiKey}`)
            .then(response => response.json())
            .then(data => {
              const location = data.results[0].geometry.location;
              const addressComponents = data.results[0].address_components;
              const city = addressComponents.find(component => component.types.includes("locality")).long_name;
              const state = addressComponents.find(component => component.types.includes("administrative_area_level_1")).long_name;
              const country = addressComponents.find(component => component.types.includes("country")).long_name;
              console.log({ city, state, country, ...location });
            })
            .catch(error => console.error(error));

           */

            // if (search.keywords) {
            //   body.searchKey = search.keywords.split(", ");
            // }

            // if (search.loc) {
            //   console.log(JSON.parse(search.loc));
            //   body.filter.push({
            //     type: "location",
            //     location: JSON.parse(search.loc),
            //   });
            // }

            // if (search.experience) {
            //   body.filter.push({
            //     type: "experience",
            //     maxExp: search.experience,
            //     minExp: 0,
            //   });
            // }
            // if (search.page) {
            //   body.skip = (search.page - 1) * 10;
            // }
            // console.log(JSON.stringify(body));

            let searchbody;
            // console.log("STEP22:SearchID", search?.searchID)

            if (search?.searchID !== "" && search?.searchID !== undefined) {
              try {
                console.log("STEP22.1:SearchID", context?.companyID);

                // loggers.info("STEP22.1" + context)
                let searchreslutsdata = await axios.get(
                  context.domain +
                    `/jsv4/webapi/jobsearch/SearchIdResluts/${search.searchID}`
                );
                if (searchreslutsdata?.data?.data) {
                  searchbody = searchreslutsdata;
                } else {
                  searchbody = {};
                }
                console.log("STEP24:SearchIdResluts", searchbody?.data);
              } catch (error) {
                console.log("STEP24:errorfromSearchIdResluts", error.stack);
                // console.log("errorfromSearchIdResluts", error.stack);
                searchbody = {};
              }
            } else {
              searchbody = {};
            }

            let searchdata;
            if (
              searchbody?.data?.data?.body &&
              searchbody?.data?.data?.body.companyID == context?.companyID
            ) {
              searchdata = searchbody?.data?.data?.body;
              searchdata.searchID = search?.searchID;
              searchdata.newSearch = false;
              searchdata.searchqureydata = true;
              searchdata.filter = [];
            } else {
              searchdata = {};
              searchdata.companyID = context?.companyID ?? "";
              searchdata.searchAttributes = false;
              searchdata.newSearch = true;
              searchdata.sort = [];
              searchdata.search = [];
              searchdata.projection = [
                "title",
                "createdAt",
                "displayName",
                "job.details.jobTitle.text",
                "job.requirements.skills.skills.text",
                "job.details.locations",
                "job.details.jobTitle.jobType",
                "job.details.offeredSalary",
                "job.details.jobTiming",
                "job.details.workingHours",
                "job.metaData.postedDate",
                "job.details.jobDescription.text",
                "job.otherInformation.industry.classified.industryLabel",
                "customData.6112806bc9147f673d28c6eb.64abb309db7899f5908c77aa.value",
                "passcode",
              ];
              if (search?.keywords) {
                searchdata.searchKey = search.keywords.split(", ");
              }
              if (search?.industry) {
                searchdata.search.push({
                  type: "industry",
                  industry: search.industry.split(", "),
                });
              }
              /*  if (search.loc) {
                 searchdata.filter = [
                   {
                     type: "location",
                     location: [JSON.parse(search.loc)],
                   },
                 ];
               } */

              let location = {
                // city: search.city /* || search.contry, */,
                // state: search.state,
                // country: search.country /* || search.contry */,
                // zipcode: search.zipcode,
              };
              if (search?.locationtext) {
                location.text = search.locationtext;
              }
              if (search?.city) {
                location.city = search.city;
              }
              if (search?.state) {
                location.state = search.state;
              }

              if (search?.country) {
                location.country = search.country;
              }
              if (search?.zipcode) {
                location.zipcode = search.zipcode;
              }
              if (
                search?.lat &&
                search?.lon &&
                search?.lat != "" &&
                search?.lon != ""
              ) {
                location.pin = {
                  lat: search.lat,
                  lon: search.lon,
                };
              }

              if (Object.values(location).some((value) => value)) {
                searchdata.search.push({
                  type: "location",
                  location: [location],
                });
              }

              if (search?.experience) {
                searchdata.search.push({
                  type: "experience",
                  maxExp: search.experience,
                  minExp: 0,
                });
              }
            }
            if (search?.minExp || search?.maxExp) {
              searchdata.search.push({
                type: "experience",
                maxExp: search.maxExp,
                minExp: search.minExp,
              });
            }
            // if (search.page) {
            //   searchdata.skip = (search.page - 1) * 6;
            // } else {
            searchdata.skip = 0;
            searchdata.page = 1;
            searchdata.size = 6;
            //}
            // if (search.loc) {
            //   searchdata.filter = {
            //     type: "location",
            //     location: JSON.parse(search.loc),
            //   };
            // } else {
            //   searchdata.filter = {};
            // }

            if (
              searchdata?.search?.length > 0 ||
              searchdata?.searchKey?.length > 0
            ) {
              searchdata.sort = [{ type: "relevance", order: "desc" }];
            } else {
              searchdata.sort = [{ type: "date", order: "desc" }];
            }

            let data;
            try {
              if (searchdata?.companyID !== "") {
                console.log("STEP26:domain", context.domain);
                data = await axios.post(
                  context.domain + "/jsv4/webapi/jobsearch/search",
                  searchdata
                );
                console.log("STEP38:", data?.data?.searchID);
              } else {
                data = {};
              }
            } catch (error) {
              console.log("STEP39:jobsearchError", error);

              // console.error("jobsearch", error);
              data = {};
            }
            console.log("STEP40:serachjobsdata", data?.data?.searchID);

            // console.log("serachjobsdata", data?.data);
            // console.log(searchdata, "dfghjhjk");
            // console.log(data);
            // console.log(data?.data?.data?.attributesAll.location[0], "satish");
            return {
              companyID: context?.companyID ?? "",
              // searchJson: body,
              jobs: data?.data ?? {},
              searchbody: searchbody?.data?.data?.body ?? searchdata,
              companyName: context?.themeVariables?.companyName ?? "",
              companydetails: context?.themeVariables,
            };
            // { searchJson: body, jobs: data?.data, context: context } ?? {}
          } catch (error) {
            console.log("STEP41:errorfrom serchJobs", error.stack);

            // console.log("errorfrom serchJobs", error.stack);
            return {};
          }

          // let searchJson = JSON.parse(JSON.stringify(searchJson));
          // return { jobs: searchJson };
        },
      },
      {
        path: "login",
        loader: async ({ request, params, context }) => {
          return {
            companydetails: context?.themeVariables,
          };
        },
        element: <Login />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/careers/createprofile",
        loader: async ({ request, params, context }) => {
            return {
              // jobDetails: data?.data?.data ?? {},
              companyID: context?.companyID ?? "",
              // companydetails: context?.themeVariables,
              // cookies: context.cookies,
            };
        },
        element: <CreateProfile />,
        errorElement: <ErrorBoundary />,
      },
      // {
      //   path: "forgotpassword",
      //   element: <ForgotPassword />,
      //   errorElement: <ErrorBoundary />,
      // },
      {
        path: "about",
        element: <About />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/search",
        element: <Search />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/careers/jobsone",
        element: <LayoutMain />,
        id: "jobsone",
        errorElement: <ErrorBoundary />,
        loader: async ({ request, params, context }) => {
          return context ?? {};
        },
      },
      {
        // path: "/list/:searchID",
        path: "/careers/list",
        loader: async ({ request, params, context }) => {
          return context ?? {};
        },
        element: <ListJobs />,
        id: "list",
        errorElement: <ErrorBoundary />,
      },
      {
        path: "dashboard",
        loader: dashboardLoader,
        element: <Dashboard />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "lazy",
        lazy: () => import("./lazy"),
      },
      {
        path: "redirect",
        loader: redirectLoader,
      },
      {
        path: "/careers/jobdetails/:campaignId",
        loader: async ({ request, params, context }) => {
          let { campaignId } = params;
          // console.log("params", params);
          try {
            let data = await axios.get(
              context.domain + "/jsv4/webapi/getjobdetails/" + campaignId
            );
            // console.log(data?.data, "jobdearrahh");
            return {
              jobDetails: data?.data?.data ?? {},
              // companyID: context?.companyID ?? "",
              companydetails: context?.themeVariables,
              cookies: context.cookies,
            };
          } catch (error) {
            console.log(error.stack);
            return {};
          }
        },
        element: <CareersJobdeatails />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/careers/referral/:referralcode",
        element: <Referral />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/careers/schedulevideo", //schedulevideo?iid=64a54273b3da18296dc79b62&companyid=552d016291bad07ccbb455fe&round=4
        element: <ScheduleVideo />,
        errorElement: <ErrorBoundary />,
        loader: async ({ request, params, context }) => {
          const url = new URL(request.url);
          let interviewID = url.searchParams.get("iid");
          let companyID = url.searchParams.get("companyid");
          let roundnumber = url.searchParams.get("round");
          let campaignID = url.searchParams.get("cid");

          // check whether campaign is active or not
          try {
            let campaignStatus = await axios.get(
              context.domain + "/jsv4/webapi/schedulevideo/checkCampaignStatus",
              {
                params: {
                  interviewID: interviewID ?? "",
                  roundNumber: roundnumber ?? "",
                  companyID: companyID ?? "",
                  campaignID: campaignID ?? "",
                },
              }
            );
            console.log("campaignStatus : ", campaignStatus?.data);

            if (
              campaignStatus?.data?.status &&
              campaignStatus?.data?.result?.isCampaignActive
            ) {
              campaignID = campaignStatus?.data?.result?.campaignID;
              companyID = campaignStatus?.data?.result?.companyID;
              return {
                // interviewID: interviewID,
                campaignID: campaignID,
                // roundNumber: roundnumber,
                companyID: companyID,
              };
            } else {
              return {
                message: "Campaign is not active",
              };
            }
          } catch (error) {
            console.log("Error" + error.stack);
            return null;
          }
        },
      },
      {
        path: "/careers/confirmschedule",
        element: <ConfirmSchedule />,
        errorElement: <ErrorBoundary />,
        loader: async ({ context, request, params }) => {
          const url = new URL(request.url);
          let status = url.searchParams.get("status");
          let interviewID = url.searchParams.get("iid");
          let roundnumber = url.searchParams.get("round");
          let companyID = url.searchParams.get("companyid");
          let participant = url.searchParams.get("participant");

          console.log("fghgfdsaSDFG", interviewID, roundnumber, context.domain);

          if (interviewID !== null && roundnumber !== null) {
            try {
              let campaignStatus = await axios.get(
                context.domain +
                  "/jsv4/webapi/schedulevideo/checkCampaignStatus",
                {
                  params: {
                    interviewID: interviewID ?? "",
                    roundNumber: roundnumber ?? "",
                    companyID: companyID ?? "",
                  },
                }
              );
              console.log("campaignStatus : ", campaignStatus?.data);

              if (
                campaignStatus?.data?.status &&
                campaignStatus?.data?.result?.isCampaignActive
              ) {
                try {
                  let isExpired = await axios.get(
                    context.domain +
                      "/jsv4/webapi/schedulevideo/checkInvitationStatus",
                    {
                      params: {
                        interviewID: interviewID,
                        roundNumber: roundnumber,
                        companyID: companyID,
                        status: status,
                        participant: participant,
                      },
                    }
                  );

                  console.log("responseeeee", isExpired?.data);

                  // eventFlag --> checks whether it more than current time and block time

                  if (isExpired?.data?.status) {
                    if (isExpired?.data?.result?.eventFlag) {
                      if (
                        isExpired?.data?.result?.renderPage ==
                        "confirmscheduleExpired"
                      ) {
                        return {
                          expired: true,
                          message: "This invitation has expired",
                        };
                      } else if (
                        isExpired?.data?.result?.renderPage == "Direct Schedule"
                      ) {
                        if (
                          isExpired?.data?.result?.allowJobseekerToReschedule
                        ) {
                          return {
                            expired: false,
                            renderPage: isExpired?.data?.result?.renderPage,
                          };
                        } else {
                          return {
                            expired: true,
                            message: "This invitation has expired",
                          };
                        }
                      } else {
                        return {
                          expired: false,
                          renderPage: isExpired?.data?.result?.renderPage,
                        };
                      }
                    } else {
                      return {
                        expired: true,
                        message: "This invitation has expired",
                      };
                    }
                  } else {
                    return isExpired?.data;
                  }
                } catch (error) {
                  console.log(
                    "ERROR IN checkInterviewBookingStatus",
                    error.stack
                  );
                  return null;
                }
              } else {
                return {
                  message: "Campaign is not active",
                };
              }
            } catch (error) {
              console.log("ERROR IN checkCampaignStatus", error.stack);
              return null;
            }
          } else {
            return null;
          }
        },
      },
      {
        path: "/careers/unsubscribe",
        element: <UnsubscribeEmail />,
        errorElement: <ErrorBoundary />,
        loader: async ({ request, params, context }) => {
          const url = new URL(request.url);
          let communicationID = "";
          let campaignID = "";
          let channel = "";
          if (url.searchParams.get("communicationid")) {
            communicationID = url.searchParams.get("communicationid");
          }
          if (url.searchParams.get("cid")) {
            campaignID = url.searchParams.get("cid");
          }
          if (url.searchParams.get("channel")) {
            channel = url.searchParams.get("channel");
          }

          try {
            let data = await axios.get(
              context.domain + "/jsv4/webapi/unsubscribe/getCommunicationData",
              {
                params: {
                  communicationID: communicationID,
                  campaignID: campaignID,
                },
              }
            );
            return data?.data;
          } catch (error) {
            return {};
          }
        },
      },
      {
        path: "/job",
        element: <Job />,
      },
      {
        path: "*",
        element: <NoMatch />,
      },
    ],
  },
  // {
  //   path: "/careers",
  //   element: <Layout />,
  //   errorElement: <ErrorBoundary />,

  //   loader: async ({ request, params, context }) => {
  //     // console.log("sdgnjksgnjk", context);
  //     return context ?? {};
  //     // try {
  //     //   // console.log("sdgnjksgnjk", getOrigin(request.url) );
  //     //   // console.log(getOrigin(request.url) +"/api/getTheme")
  //     //   let data = await axios.get(getOrigin(request.url) + "/jsv4/webapi/getTheme");
  //     //   // console.log(data?.data?.data);
  //     //   return {
  //     //     themes: data?.data ?? {},
  //     //   };
  //     // } catch (error) {
  //     //   console.log(error.stack);
  //     //   return {};
  //     // }
  //   },
  //   children: [
  //     {
  //       index: true,
  //       // loader: async ({ request, params }) => {
  //       //   console.log("sdgnjksgnjk", params);
  //       //   try {
  //       //     let data = await axios.get("/api/getTheme");
  //       //     console.log(data);
  //       //     return data?.data?.data ?? {};
  //       //   } catch (error) {
  //       //     console.log(error.stack);
  //       //     return {};
  //       //   }
  //       // },
  //       element: <Home />,
  //       errorElement: <ErrorBoundary />,
  //     },

  //     {
  //       path: "*",
  //       element: <NoMatch />,
  //     },
  //   ],
  // },
];

// function ErrorBoundary() {
//   let error = useRouteError();
//   console.error(error);
//   // Uncaught ReferenceError: path is not defined
//   return <div>{error.message}</div>;
// }
function hexToRgb(hex) {
  hex = hex.replace("#", "");
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  return r + ", " + g + ", " + b;
}
import NavHeader from "./NavHeader/Header/Header";
import NavFooter from "./NavHeader/Footer/Footer";
import usePost from "./customhooks/UsePost";
import TrcHeader from "./NavHeader/Header/TrcHeader";
import TrcFooter from "./NavHeader/Footer/TrcFooter";
import CareersHeader from "./NavHeader/Header/CareersHeader";

function getHeader(t, companyID) {
  switch (t) {
    case "TrcHeader":
      return <TrcHeader />;
    case "CareersJobdetails":
      return <></>;
    case "Careerspage":
      return <CareersHeader companyID={companyID}/>;

    default:
      return <NavHeader />;
  }
}

function getFooter(t) {
  switch (t) {
    case "TrcFooter":
      return <TrcFooter />;
    case "CareersFooter":
      return <></>;
    default:
      return <NavFooter />;
  }
}

function Layout() {
  // useEffect(() => {
  //   import("./components/jobs/SearchBar.css");
  // }, []);
  let { themeVariables ,companyID} = useLoaderData();

  console.log("STEP21:", themeVariables?.defaultLanguageCode);

  const { t, i18n } = useTranslation();
  const isSsr = useIsSsr();

  const socket = useContext(SocketContext);
  const isMounted = useRef(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [user, setUser] = useState(null);
  let navigate = useNavigate();
  useEffect(() => {
    // console.log("isMounted", isMounted?.current, isSsr);
    if (typeof window !== "undefined") {
      if (!isMounted?.current && !isSsr) {
        // if (window.location.pathname == "/") {
        //   window.location.replace("/jobs");
        //   return;
        // }
        if (window.location.host.includes("localhost:3105")) {
          if (window.location.pathname == "/") {
            window.location.replace("/jobs");
            return;
          }
        }
        console.log("socket");

        const getUser = async () => {
          try {
            // if (!window.location.pathname.includes("careers")) {
            const user = await axios.get(
              window.location.origin + "/jsv4/webapi/user/getUserDetails",
              {
                withCredentials: true,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            );
            if (user?.data?.status) {
              setUser(user?.data?.user);
            }
            //}
          } catch (error) {
            console.log(error);
          }
        };
        getUser();
        if (socket) {
          socket.on("connect", () => {
            console.log("connected");
          });
          socket.on("disconnect", () => {
            console.log("disconnected");
          });

          socket.on("check", function () {
            console.log("check");
          });
        }

        isMounted.current = true;

        // const cssStyles = themes?.jsThemeVariables ?? [];

        // if (themes) {
        //   // change language to en

        //   // document.documentElement.style.setProperty(
        //   //   "--bs-primary",
        //   //   themes?.themeVariables["mainColor"]
        //   // );
        //   // // red hex value is #ff0000
        //   // document.documentElement.style.setProperty(
        //   //   "--bs-primary-rgb",
        //   //   // hexToRgb("#ff0000")
        //   //   hexToRgb(themes?.themeVariables["mainColor"])
        //   // );

        //   // console.log("cssStyles", cssStyles);
        //   cssStyles.forEach((style) => {
        //     try {
        //       const elements = document.querySelectorAll(style.element);
        //       // console.log(elements);
        //       elements.forEach((element) => {
        //         style?.styles?.forEach((css) => {
        //           try {
        //             // element.style[css.attribute] =
        //             //   css.value + " " + css.isImportant ? " !important" : "";
        //             element.style.setProperty(css.attribute, css.value);
        //             // console.log(css.attribute)
        //             // console.log(getComputedStyle(element).getPropertyValue(css.attribute));
        //             // console.log(css.value ,(css.value + " " + css.isImportant ? " !important" : ""));
        //           } catch (error) {
        //             console.log(error);
        //           }
        //         });
        //       });
        //     } catch (error) {
        //       console.log(error);
        //     }
        //   });
        // }
      }
    }
    // return () => {
    //   axios.interceptors.response.eject(responseIntercept);
    //   axios.interceptors.request.eject(requestIntercept);
    // }
    // }
    setIsLoaded(true);
  }, [isSsr, navigate]);

  // useEffect(()=>{
  // // i18n.changeLanguage("en-us");
  // if(themeVariables && themeVariables?.defaultLanguageCode != "" && themeVariables?.defaultLanguageCode != undefined ){
  //   i18n.changeLanguage(themeVariables?.defaultLanguageCode)
  // } else {
  //   i18n.changeLanguage("en-us");
  // }

  // },[themeVariables])
  // const isMountedtrans = React.useRef(false);

  // useEffect(() => {
  //   if (themeVariables && themeVariables?.defaultLanguageCode !== "") {
  //     if (!isMountedtrans.current) {
  //       i18n.changeLanguage(themeVariables?.defaultLanguageCode);
  //       // i18next.use(Backend).init({
  //       //   lng: themeVariables?.defaultLanguageCode,
  //       //   fallbackLng: themeVariables?.defaultLanguageCode,
  //       //   backend: {
  //       //     loadPath: "/careers/getKeys?lng={{lng}}&ns={{ns}}",
  //       //   },
  //       // });
  //       isMountedtrans.current = true;
  //     }
  //   }
  // }, []);

  // console.log(themes); //5ed9edf704f216cabb36755b

  // if(!isLoaded){
  //   return (<>Loading...</>)
  // }

  // const [NavHeaderComponent, setNavHeaderComponent] = useState(null);
  // const [NavFooterComponent, setNavFooterComponent] = useState(null);

  // useEffect(() => {
  //   async function loadNavHeader() {
  //     let NavHeader = null;
  //     try {
  //       NavHeader = await import(
  //         "./NavHeader/Header/" +
  //           themeVariables?.jobSeekerLandingPage?.navHeaderComponent
  //       );
  //     } catch (error) {
  //       console.log(error);
  //       NavHeader = await import("./NavHeader/Header/Header");
  //     }
  //     setNavHeaderComponent(NavHeader.default);
  //   }
  //   async function loadNavFooter() {
  //     // const NavFooter = await import("./NavHeader/Footer/Footer");
  //     let NavFooter = null;
  //     try {
  //       NavFooter = await import(
  //         "./NavHeader/Footer/" +
  //           themeVariables?.jobSeekerLandingPage?.navFooterComponent
  //       );
  //     } catch (error) {
  //       console.log(error);
  //       NavFooter = await import("./NavHeader/Footer/Footer");
  //     }
  //     setNavFooterComponent(NavFooter.default);
  //   }
  //   if (
  //     window.location.href.includes("jobdetails") ||
  //     window.location.href.includes("jobs")
  //   ) {
  //     loadNavHeader();
  //     loadNavFooter();
  //   }
  //   // loadNavHeader();
  //   // loadNavFooter();
  // }, []);
  const previousURL = React.useRef(null);
  const { post: getPagevisits, data, isLoading, error } = usePost("/pagevisit");
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (themeVariables?.companyID) {
        let path = window.location.href;
        let title;
        let titleExists = true;
        let page = "";
        let subPage = "";
        if (path.includes("dashboard")) {
          // title = `Profiles | Folders |  ${userDetails?.companyName}`;
          title = `dashboard`;
          page = "Home";
          subPage = "Home";
        } else if (path.includes("schedulevideo")) {
          title = "Schedule Interview";
        } else if (path.includes("myaccount/basicdetails")) {
          title = `Basic Details | My Account | ${themeVariables?.companyName}`;
          page = "Basic Details";
          subPage = "My Account";
        } else if (path.includes("myaccount/favouritejobs")) {
          title = `Basic Details | Favouritejobs | ${themeVariables?.companyName}`;
          page = "Basic Details";
          subPage = "Favouritejobs";
        } else {
          titleExists = false;
          title = themeVariables?.companyName;
        }
        // if (
        //   titleExists &&
        //   title &&
        //   previousURL.current != window.location.href
        // ) {
        //   getPagevisits({
        //     url: window.location.href,
        //     title: title,
        //     page: page,
        //     subPage: subPage,
        //     userID: user?._id ?? "",
        //     companyID: themeVariables?.companyID,
        //   }).then((res) => {
        //     console.log(res);
        //   });
        // }
        previousURL.current = window.location.href;
        document.title = title;
      }
    }
  }, [navigate]);

  // const [careerspage, setcareerspage] = useState(false);

  // useEffect(() => {
  //   if (typeof window !== "undefined") {
  //     if (window.location.pathname.includes("careers")) {
  //       setcareerspage(true);
  //     } else {
  //       setcareerspage(false);
  //     }
  //   }
  // }, [careerspage]);
  const [careerspage, setcareerspage] = useState(
    themeVariables?.jobSeekerLandingPage?.navHeaderComponent ?? ""
  );
  const [careersFooter, setcareersFooter] = useState(
    themeVariables?.jobSeekerLandingPage?.navFooterComponent ?? ""
  );
  const [noHeaderFooter, setNoHeaderFooter] = useState(false)

  useEffect(() => {
    if (typeof window !== "undefined") {
      // if (window.location.pathname.includes("careers/jobdetails")) {
      if(window.location.pathname.includes("createprofile")){
        setNoHeaderFooter(true);
      }
      if (
        /careers\/jobdetails/gi.test(window.location.href) ||
        window.location.pathname.includes("careers/jobdetails") ||
        window.location.pathname.includes("/unsubscribe") 
      ) {
        setcareerspage("CareersJobdetails");
        setcareersFooter("CareersFooter");
        // })
        // setcareerspage("CareersJobdetails");
        // setcareersFooter("CareersFooter");
      } else if (
        window.location.pathname.includes("/schedulevideo") ||
        window.location.pathname.includes("/confirmschedule") ||
        window.location.pathname.includes("careers/referral")

      ) {
        setcareerspage("Careerspage");
        setcareersFooter("CareersFooter");
      }
      // else if (
      //   window.location.pathname.includes("careers/jobdetails") ||
      //   window.location.pathname.includes("/unsubscribe")
      // ) {
      //   setcareerspage("CareersJobdetails");
      //   setcareersFooter("CareersFooter");
      // }
      else {
        setcareerspage(
          themeVariables?.jobSeekerLandingPage?.navHeaderComponent
        );
        setcareersFooter(
          themeVariables?.jobSeekerLandingPage?.navFooterComponent
        );
      }
    }
  }, [careerspage, careersFooter, themeVariables]);
  return (
    <>
      <UserContext.Provider
        // value={{ user: userDetails, setUserDetails: setUserDetails }}
        value={{ user, setUser }}
      >
        <div
          className="Total-content"
          // style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
        >
          <div style={{ flex: "1" }}>
            {/* {NavHeaderComponent} */}
            {/* <NavHeader /> */}
            {!noHeaderFooter && getHeader(careerspage,companyID)}
            {/* <div className="container mb-3" style={{ marginTop: "150px" }}>
    <Outlet />
    </div> */}
            <div
              className={`${
                careerspage == "TrcHeader" && !noHeaderFooter
                  ? "container mb-3 "
                  : "container-fluid ps-4"
              }`}
              style={{
                marginTop: !noHeaderFooter && careerspage == "TrcHeader" ? "150px" : "5px",
              }}
            >
              <Outlet />
            </div>
          </div>
          {/* {NavFooterComponent} */}
          {/* <NavFooter /> */}
          {/* {getFooter(
    careerspage
    ? "CareersFooter"
    : themeVariables?.jobSeekerLandingPage?.navFooterComponent
    )} */}
          {!noHeaderFooter && getFooter(careersFooter)}
        </div>
      </UserContext.Provider>
      {/* <div className="postion-relative"> */}
      {/* <NavHeader /> */}
      {/* {NavHeaderComponent} */}
      {/* <div className="container my-3 main-container"> */}
      {/* <h1>Data Router Server Rendering Example</h1>
   
    <p>
    If you check out the HTML source of this page, you'll notice that it
    already contains the HTML markup of the app that was sent from the
    server, and all the loader data was pre-fetched!
    </p>
   
    <p>
    This is great for search engines that need to index this page. It's also
    great for users because server-rendered pages tend to load more quickly
    on mobile devices and over slow networks.
    </p>
   
    <p>
    Another thing to notice is that when you click one of the links below
    and navigate to a different URL, then hit the refresh button on your
    browser, the server is able to generate the HTML markup for that page as
    well because you're using React Router on the server. This creates a
    seamless experience both for your users navigating around your site and
    for developers on your team who get to use the same routing library in
    both places.
    </p>
   
    <nav>
    <ul>
    <li>
    <Link to="/">Home</Link>
    </li>
    <li>
    <Link to="/about">About</Link>
    </li>
    <li>
    <Link to="/dashboard">Dashboard</Link>
    </li>
    <li>
    <Link to="/lazy">Lazy</Link>
    </li>
    <li>
    <Link to="/redirect">Redirect to Home</Link>
    </li>
    <li>
    <Link to="/nothing-here">Nothing Here</Link>
    </li>
    </ul>
    </nav>
   
    <hr /> */}
      {/* <Outlet/> */}

      {/* <iframe src="http://localhost:3105/jobdetails/6466f9cf9cf5a526c2baf40b"></iframe> */}

      {/* <Outlet /> */}
      {/* </div> */}
      {/* <NavFooter /> */}
      {/* {NavFooterComponent} */}
      {/* </div> */}
    </>
  );
}

const sleep = (n = 500) => new Promise((r) => setTimeout(r, n));
const rand = () => Math.round(Math.random() * 100);

// async function homeLoader() {
//   // await sleep();
//   // axios
//   //   .get("/api")
//   //   .then((res) => {
//   //     console.log(res.data);
//   //     return { data: `Home loader - random value ${rand()}` };
//   //   })
//   //   .catch((err) => {
//   //     console.log(err);
//   //   });

//   try {
//     // let data = await axios.get("/api");
//     // console.log(data.data);
//     let user = await usersCollection.findOne({});
//     // console.log(user);
//     return user;
//   } catch (err) {
//     console.log(err.stack);
//     return null;
//   }

//   // return { data: `Home loader - random value ${rand()}` };
// }

function Home() {
  // const navigate = useNavigate();
  // useEffect(() => {
  //   window.location.replace("/jobs");
  // }, [navigate]);

  // let data = useLoaderData();
  return <div>Home</div>;
}

function About() {
  return (
    <div>
      <h2>About</h2>
    </div>
  );
}

async function dashboardLoader() {
  await sleep();
  return { data: `Dashboard loader - random value ${rand()}` };
}

function Dashboard() {
  let data = useLoaderData();
  return (
    <div>
      <h2>Dashboard</h2>
      <p>Loader Data: {data.data}</p>
    </div>
  );
}

async function redirectLoader() {
  await sleep();
  return redirect("/");
}

function Job() {
  useEffect(() => {
    window.location.replace("/jobs");
  }, []);
}

function NoMatch() {
  let { "*": wildcardParam } = useParams();
  useEffect(() => {
    if (wildcardParam) {
      const regex = /job\/([^\/]+)\/([^\/]+)/;
      const match = wildcardParam.match(regex);
      console.log(match, "match");
      if (match) {
        const passcode = match[1];
        const jobTitle = match[2];
        if (passcode) {
          window.location.replace(window.location.origin + `/job/${passcode}`);
        }
      }
    }
  }, []);

  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
