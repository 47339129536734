import React,{useState,useEffect} from 'react'
import CreateManualProfileMain from '../createProfile/CreateManualProfileMain'
import { useForm } from 'react-hook-form';
import { useLoaderData, useSearchParams } from 'react-router-dom';
import usePost from '../../customhooks/UsePost';
import DuplicateProfile from '../createProfile/DuplicateProfile';
import useIsSsr from '../../customhooks/UseIsSSR';
import { Modal, toaster, Notification, Loader } from "rsuite";
import { useTranslation } from 'react-i18next';

const CreateProfile = () => {
    const [step, setStep] = useState(0);
    const [searchParams] = useSearchParams();
    const [notduplicate, setnotduplicate] = useState(false);
    const [openDuplicate, setOpenDuplicate] = useState(false);
    const [duplicateProfiles, setDuplicateProfiles] = useState([]);
    const isSsr = useIsSsr();
    const [resumeID, setResumeID] = useState([]);
    const { t } = useTranslation();

    const {
        register,
        control,
        getValues,
        setValue,
        watch,
        clearErrors,
        formState: { errors },
        setError,
        handleSubmit,
        reset,
      } = useForm();
      let loaderDetails = useLoaderData();

      function checkEmailDuplicateOnSubmit(emails) {
        let emailAvailable = true;
        for (let i = 0; i < emails.length; i++) {
          for (let j = 0; j < emails.length; j++) {
            if (i != j) {
              if (emails[i]?.email == emails[j]?.email) {
                setError("emails[" + j + "].email", {
                  type: "manual",
                  message: t("emailAlreadyExists", {
                    defaultValue: "Email already exists",
                  }),
                });
                emailAvailable = false;
              }
            }
          }
        }
        return emailAvailable;
      }
    
      function checkPhoneNumberDuplicateOnSubmit(phones) {
        let phoneNumberAvailable = true;
        for (let i = 0; i < phones.length; i++) {
          for (let j = 0; j < phones.length; j++) {
            if (i != j) {
              if (phones[i]?.phone == phones[j]?.phone) {
                setError("phones[" + j + "].phone", {
                  type: "manual",
                  message: t("phonenumberIsAlreadyPresent", {
                    defaultValue: "Phonenumber is already present",
                  }),
                });
                phoneNumberAvailable = false;
              }
            }
          }
        }
        return phoneNumberAvailable;
      }

      const {
        post: createUserResumes,
        data: createUserResumesData,
        isLoading: createUserResumesLoading,
        error: createUserResumesError,
      } = usePost("/user/createUserResumes");

      useEffect(()=>{
        if(notduplicate){
          console.log("notttttttttttttttttttttt",notduplicate)
          createProfileModalSubmit()
        }
      },[notduplicate])

      const createProfileModalSubmit = () => {
        let flag = true;
        if (getValues()) {
          // console.log("allvalues:", getValues())
    
          let createResumeObj = {
            phones: [],
            skills: [],
            from: "Create Profile",
            source:
              searchParams.get("source") != null ? searchParams.get("source") : "",
            referenceURL: isSsr
              ? ""
              : window.location.origin + window.location.pathname,
          };
          createResumeObj.companyID = loaderDetails?.companyID;
          // basic details
          createResumeObj.firstName =
            getValues()?.firstName !== undefined ? getValues()?.firstName : "";
          createResumeObj.middleName =
            getValues()?.middleName !== undefined ? getValues()?.middleName : "";
          createResumeObj.lastName =
            getValues()?.lastName !== undefined ? getValues()?.lastName : "";
          createResumeObj.city =
            getValues()?.profileCity !== undefined ? getValues()?.profileCity : "";
          createResumeObj.state =
            getValues()?.profileState !== undefined
              ? getValues()?.profileState
              : "";
          createResumeObj.country =
            getValues()?.profileCountry !== undefined
              ? getValues()?.profileCountry
              : "";
          createResumeObj.postalCode =
            getValues()?.profilePostalCode !== undefined
              ? getValues()?.profilePostalCode
              : "";
          createResumeObj.location =
            getValues()?.profileLocation !== undefined
              ? getValues()?.profileLocation
              : "";
          if (getValues()?.emails) {
            if (getValues()?.emails?.length > 0) {
              if (!checkEmailDuplicateOnSubmit(getValues()?.emails)) {
                flag = false;
              }
              createResumeObj.emails = getValues()?.emails;
            }
          }
          if (getValues()?.phones) {
            if (getValues()?.phones?.length > 0) {
              let phones = getValues()?.phones;
              if (!checkPhoneNumberDuplicateOnSubmit(phones)) {
                flag = false;
              }
    
              for (let i in phones) {
                if (
                  phones[i]?.phone?.trim() != "" &&
                  phones[i]?.phone !== undefined
                ) {
                  let phoneSchema = {
                    phone: "",
                    country: "",
                    countryCode: "",
                    internationalPhoneCode: "",
                    phoneNumberOnly: "",
                  };
                  let parsedNumber = parsePhoneNumber(String(phones[i]?.phone));
                  phoneSchema.phone = parsedNumber?.number;
                  phoneSchema.country = countryNames[parsedNumber?.country];
                  phoneSchema.countryCode = parsedNumber?.country;
                  phoneSchema.internationalPhoneCode =
                    parsedNumber?.countryCallingCode;
                  phoneSchema.phoneNumberOnly = parsedNumber?.nationalNumber;
                  createResumeObj.phones.push(phoneSchema);
                }
              }
            }
          }
    
          // summary
          createResumeObj.objective =
            getValues()?.Objective !== undefined ? getValues()?.Objective : "";
          createResumeObj.summary =
            getValues()?.Summary !== undefined ? getValues()?.Summary : "";
          if (getValues()?.skills) {
            if (getValues()?.skills?.length > 0) {
              let skills = getValues()?.skills;
              for (let i in skills) {
                let skill = {};
                skill.skill = skills[i]?.skills;
                if (skills[i]?.yearsOfExperience == "") {
                  skill.yearsOfExperience = 0;
                } else {
                  skill.yearsOfExperience = parseInt(skills[i]?.yearsOfExperience);
                }
                createResumeObj.skills.push(skill);
              }
            }
          }
    
          createResumeObj.education =
            getValues()?.education !== undefined ? getValues()?.education : [];
          createResumeObj.experience =
            getValues()?.experience !== undefined ? getValues()?.experience : [];
    
          // other info
          if (
            getValues()?.minimumExpectedSalary !== undefined &&
            getValues()?.maximumExpectedSalary !== undefined
          ) {
            if (
              getValues()?.minimumExpectedSalary?.trim() != "" &&
              getValues()?.maximumExpectedSalary?.trim() != ""
            ) {
              if (
                parseInt(getValues()?.minimumExpectedSalary) >
                parseInt(getValues()?.maximumExpectedSalary)
              ) {
                flag = false;
                setError("minimumExpectedSalary", {
                  type: "manual",
                  message: (
                    <Trans
                      i18nKey="minimumExpectedSalaryShouldBeLessThanMaximumExpectedSalary"
                      defaults="Minimum expected salary should be less than maximum expected salary"
                    />
                  ),
                });
              }
            }
          }
    
          createResumeObj.currentSalary =
            getValues()?.currentSalary !== undefined
              ? getValues()?.currentSalary
              : "";
          createResumeObj.salaryPeriod =
            getValues()?.salaryPeriod !== undefined
              ? getValues()?.salaryPeriod
              : "";
          createResumeObj.salaryCurrency =
            getValues()?.salaryCurrency !== undefined
              ? getValues()?.salaryCurrency
              : "";
          createResumeObj.noticePeriod =
            getValues()?.noticePeriod !== undefined
              ? getValues()?.noticePeriod
              : "";
          createResumeObj.workPermit =
            getValues()?.workPermit !== undefined ? getValues()?.workPermit : "";
          createResumeObj.gender =
            getValues()?.gender !== undefined ? getValues()?.gender : "";
          createResumeObj.veteranStatus =
            getValues()?.veteranStatus !== undefined
              ? getValues()?.veteranStatus
              : "";
          createResumeObj.dateOfBirth =
            getValues()?.dateOfBirth !== undefined ? getValues()?.dateOfBirth : "";
          createResumeObj.ageData =
            getValues()?.ageData !== undefined ? getValues()?.ageData : "";
          createResumeObj.maritalStatus =
            getValues()?.maritalStatus !== undefined
              ? getValues()?.maritalStatus
              : "";
          createResumeObj.nationality =
            getValues()?.nationality !== undefined ? getValues()?.nationality : "";
          createResumeObj.languagesKnown =
            getValues()?.languagesKnown !== undefined
              ? getValues()?.languagesKnown
              : [];
          createResumeObj.websites =
            getValues()?.websites !== undefined ? getValues()?.websites : [];
          createResumeObj.preferredLocations =
            getValues()?.preferred !== undefined ? getValues()?.preferred : [];
          createResumeObj.maximumExpectedSalary =
            getValues()?.maximumExpectedSalary !== undefined
              ? getValues()?.maximumExpectedSalary
              : "";
          createResumeObj.minimumExpectedSalary =
            getValues()?.minimumExpectedSalary !== undefined
              ? getValues()?.minimumExpectedSalary
              : "";
          createResumeObj.compensationCurrency =
            getValues()?.compensationCurrency !== undefined
              ? getValues()?.compensationCurrency
              : "";
          createResumeObj.compensationPeriod =
            getValues()?.compensationPeriod !== undefined
              ? getValues()?.compensationPeriod
              : "";
          // createResumeObj.resumeID = resumeId;
          // createResumeObj.fromUpload = fromUpload;
          if (notduplicate) {
            createResumeObj.notduplicate = false;
          } else {
            createResumeObj.notduplicate = true;
          }
          if (flag) {
            // console.log("createResumeObj", createResumeObj);
    
            // if (page == "userbasicprofile") {
              createUserResumes(createResumeObj)
                .then((resp) => {
                  console.log(
                    "response of create user from userbasic profile",
                    resp
                  );
    
                  if (resp?.status) {
                    if (resp?.result?.status && resp.result.status === true) {
                      if (
                        resp?.result?.disqualifiedExists &&
                        resp?.result?.disqualifiedExists === true
                      ) {
                        toaster.push(
                          <Notification
                            type={"warning"}
                            header={<Trans i18nKey="warning" defaults="Warning" />}
                            closable
                          >
                            <Trans
                              i18nKey="youCanNotCreateAProfileWithTheseContactDetailsTheseContactDetailsAreAssociatedWithDisqualifiedProfile"
                              defaults="You can not create a profile with these contact details. These contact details are associated with disqualified profile"
                            />
                          </Notification>,
                          {
                            placement: "topEnd",
                          }
                        );
                        // setOpen(false);
                      } else {
                        if (resp?.result?.duplicate) {
                          if (
                            resp?.result?.resumes &&
                            resp?.result?.resumes?.length > 0
                          ) {
                            // setOpen(false);
                            setOpenDuplicate(true);
                            setDuplicateProfiles(resp?.result?.resumes);
                            reset();
                            // setCreateManualProfile(false);
                          }
                          toaster.push(
                            <Notification
                              type={"success"}
                              header={t("success", { defaultValue: "Success" })}
                              closable
                            >
                              {t("duplicateProfileFound", {
                                defaultValue: "Duplicate Profile Found",
                              })}
                            </Notification>,
                            { placement: "topEnd" }
                          );
                          // setOpen(false);
                          setOpenDuplicate(true);
                          setDuplicateProfiles(resp?.result?.resumes);
                        } else {
                          if (resp?.status && resp?.result?.resumeID != "") {
                            setResumeID(resp?.result?.resumeID);
                            toaster.push(
                              <Notification
                                type={"success"}
                                header={t("success", { defaultValue: "Success" })}
                                closable
                              >
                                {t("profileCreatedSuccessfully", {
                                  defaultValue: "Profile Created Successfully",
                                })}
                              </Notification>,
                              { placement: "topEnd" }
                            );
                            setStep(0);
                            setnotduplicate(false);
                            // setOpen(false);
                            // setCreateManualProfile(false);
                            reset();
                          
                          }
                        }
                      }
                    } else {
                      toaster.push(
                        <Notification
                          type={"error"}
                          header={t("error", { defaultValue: "Error" })}
                          closable
                        >
                          {t("somethingWentWrongPleaseTryAgainLater", {
                            defaultValue:
                              "Something went wrong please try again later",
                          })}
                        </Notification>,
                        { placement: "topEnd" }
                      );
                      // setOpen(false);
                      // setCreateManualProfile(false);
                    }
                  } else {
                    toaster.push(
                      <Notification
                        type={"error"}
                        header={t("error", { defaultValue: "Error" })}
                        closable
                      >
                        {t("somethingWentWrongPleaseTryAgainLater", {
                          defaultValue:
                            "Something went wrong please try again later",
                        })}
                      </Notification>,
                      { placement: "topEnd" }
                    );
                  }
                })
                .catch((error) => {
                  console.log(
                    "error in create Resumes from userbasicprofile: ",
                    error
                  );
                });
            // } 
          }
        }
      };
  return (
    <div className='card'>
      <div className="card-body">
        <CreateManualProfileMain 
            register={register}
            control={control}
            step={step}
            setStep={setStep}
            setValue={setValue}
            handleSubmit={handleSubmit}
            getValues={getValues}
            errors={errors}
            page={"userbasicprofile"}
            watch={watch}
            clearErrors={clearErrors}
            required={false}
            setError={setError}
            resumeId=""
            campaignID=""
            from = {"Create Profile"}
            createProfileModalSubmit= {createProfileModalSubmit}
        />
      </div>
      {openDuplicate && (
        <React.Suspense
          fallback={<Loader center speed="normal" size="sm" backdrop={true} />}
        >
          <DuplicateProfile
            open={openDuplicate}
            setOpen={setOpenDuplicate}
            duplicateProfiles={duplicateProfiles}
            // campaignID={campaignID}
            setResumeID={setResumeID}
            setStep={setStep}
            page={"Create Profile"}
            // setCookie={setCookie}
            setupdatadataMri={false}
            setnotduplicate={setnotduplicate}
            setOpenCreateProfile={false}
          />
        </React.Suspense>
      )
      }
    </div>
  )
}

export default CreateProfile