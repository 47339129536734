import React, { useCallback, useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { NavLink, useSearchParams } from "react-router-dom";
import axios from "axios";
import { DateTime } from "luxon";
import AvailableSlots from "./AvailableSlots";
import ScheduledSlotDetails from "./ScheduledSlotDetails";
import useIsSsr from "../../customhooks/UseIsSSR";
import { Loader } from "rsuite";
import ResendMail from "./ResendMail";


export default function RescheduleSlot() {
    let [searchParams] = useSearchParams();
    const [intialSlot, setInitialSlot] = useState(null);
    const [rescheduledSlot, setResecheduledSlot] = useState(null)
    const [data, setData] = useState(null)
    const [interviewID, setInterviewID] = useState(null);
    const [companyID, setCompanyID] = useState(null);
    const [roundNumber, setRoundNumber] = useState(null);
    const [isRescheduled, setIsRescheduled] = useState(false);
    const [campaignID, setCampaignID] = useState(null);
    const [payload1, setPayload1] = useState(null);
    const [payload2, setPayload2] = useState(null);

    useEffect(() => {
        if (searchParams.get("iid") !== null) {
            setInterviewID(searchParams.get("iid"))
        }
        if (searchParams.get("companyid") !== null) {
            setCompanyID(searchParams.get("companyid"))
        }
        if (searchParams.get("round") !== null) {
            setRoundNumber(searchParams.get("round"))
        }
    }, [searchParams]);

    useEffect(() => {
        setData({
            interviewID: interviewID,
            companyID: companyID,
            roundNumber: roundNumber
        })
    }, [interviewID, companyID, roundNumber])

    useEffect(() => {
        if (interviewID && companyID) {
            setPayload1({
                interviewID: interviewID,
                companyID: companyID
            })
        }
    }, [interviewID, companyID]);

    const getCampaignID = async () => {
        try {
            let getInterview = await axios.get(
                window.location.origin + "/jsv4/webapi/getInterviewDetails",
                {
                    params: payload1
                }
            )

            if (getInterview?.data?.status && getInterview?.data?.result?.status) {
                setCampaignID(getInterview?.data?.result?.interview?.campaignID)
            } else {
                console.log("getInterviewDetails: ", getInterview?.data);
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    useEffect(() => {
        if (payload1 !== null) {
            getCampaignID();
        }
    }, [payload1])

    useEffect(() => {
        if (interviewID && campaignID && roundNumber) {
            setPayload2({
                interviewID: interviewID,
                roundNumber: roundNumber,
                campaignID: campaignID,
                companyID: companyID,
            })
        }
    }, [interviewID, campaignID, roundNumber])


    const isInterviewBooked = async () => {
        try {
            let slotDetails = await axios.get(
                window.location.origin +
                "/jsv4/webapi/schedulevideo/checkInterviewBookingStatus",
                {
                    params: payload2
                }
            );

            if (slotDetails?.data?.status && slotDetails?.data?.result?.status) {
                if (slotDetails?.data?.result?.selected) {
                    setInitialSlot(slotDetails?.data?.result)
                }
            } else {
                console.log("checkInterviewBookingStatus: ", slotDetails?.data)
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    useEffect(() => {
        if (payload2 !== null) {
            isInterviewBooked();
        }
    }, [payload2])

    const bookedSlotDetails = useCallback((data) => {
        // console.log("dhgshdhsdfsdsdf", data)
        // setIsRescheduled(true);
        setResecheduledSlot(data);
    }, [])

    return (
        <>
            {
                isRescheduled ? (
                    <ScheduledSlotDetails data={rescheduledSlot} />
                ) : (
                    <div className="row rescheduleSlotDetails">
                        <span className="pb-2 fs-4 text-muted"><Trans i18nKey="bookedSlot" defaults="Booked Slot" /></span>
                        <div className="row pb-3 text-muted">
                            <div className="col-md-4 col-xs-12">
                                <b><Trans i18nKey="scheduledDate" defaults="Scheduled Date" /></b>{" "}{":"}{" "}
                                {
                                    intialSlot?.slotDetails ? (
                                        DateTime.fromISO(intialSlot?.slotDetails?.startDate).setZone(intialSlot?.slotDetails?.timeZoneFullName).toFormat("EEEE, MMMM dd, yyyy")
                                    ) : null
                                }
                            </div>
                            <div className="col-md-4 col-xs-12">
                                <b><Trans i18nKey="scheduledTimeSlot" defaults="Scheduled Time Slot" /></b>{" "}{":"}{" "}
                                {
                                    intialSlot?.slotDetails ? (
                                        DateTime.fromISO(intialSlot?.slotDetails?.startDate).setZone(intialSlot?.slotDetails?.timeZoneFullName).toFormat(`${intialSlot?.defaultTimeFormat == "12 hours" ? "hh:mm a" : "HH:mm"}`)
                                    ) : null
                                }
                                {
                                    intialSlot?.slotDetails && intialSlot?.slotDetails?.endDate != "" ?
                                        (<>
                                            {" "}{"-"}{" "}
                                            {DateTime.fromISO(intialSlot?.slotDetails?.endDate).setZone(intialSlot?.slotDetails?.timeZoneFullName).toFormat(`${intialSlot?.defaultTimeFormat == "12 hours" ? "hh:mm a" : "HH:mm"}`)}
                                            {" "}
                                        </>) : null
                                }
                            </div>
                            <div className="col-md-4 col-xs-12">
                                <b><Trans i18nKey="scheduledTimeZone" defaults="Scheduled Time Zone" /></b>{" "}{":"}{" "}
                                {intialSlot?.slotDetails?.timeZoneFullName}
                            </div>
                        </div>
                        <div className="row pb-2 text-muted">
                            <div className="row">
                                <div className="col-12">
                                    {"*"}
                                    <Trans
                                        i18nKey="calendarInviteHasBeenSentToYourEmailAddress.PleaseCheckYourE-mailInboxOrSpamFolder.ThankYou!"
                                        defaults="Calendar invite has been sent to your email address. Please check your E-mail Inbox or Spam folder. Thank You!" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <b>
                                        <Trans
                                            i18nKey="ifYouHaveNotReceivedIt,PleaseSendOutAnEmailTo"
                                            defaults="If you have not received it, please send out an email to" />
                                        {" "}{intialSlot?.companySupportEmail}
                                    </b>
                                </div>
                            </div>
                            <div className="row py-3">
                                <div className="col-12">
                                    <ResendMail
                                        interviewID={interviewID}
                                        companyID={companyID}
                                        roundNumber={roundNumber}
                                        slotID={intialSlot?.slotDetails?._id}
                                        clientID={intialSlot?.slotDetails?.clientID}
                                        companySupportEmail={intialSlot?.companySupportEmail}
                                    />
                                </div>
                            </div>
                        </div>
                        <span className="pb-2 h5"><Trans i18nKey="selectNewSlot" defaults="Select New Slot" /></span>
                    </div>
                )
            }

            {/* available slots details showing*/}
            {
                !isRescheduled && (
                    <AvailableSlots
                        data={data}
                        isSlotSelected={isRescheduled}
                        setIsSlotSelected={setIsRescheduled}
                        bookedSlotDetails={bookedSlotDetails}
                    />
                )
            }
        </>
    )
}